import { FileModel } from "@aecom/core";
import JSZip from 'jszip';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import FileModelWithSize from "@models/fileModelWithSize";
import FileModelWithVersion from "@models/fileModelWithVersion";
import AzureBlobService from "@services/azureBlob.service";
import environment from "src/environments/environment";
import LoadingService from "@services/loading.service";

@Component({
  selector: "file-list",
  templateUrl: "./fileList.component.html",
  styleUrls: ["./fileList.component.scss"],
})
export default class FileListComponent implements OnInit {
  @Input() fileList: FileModelWithVersion[];

  @Input() title?: string;

  @Input() showDetail = false;

  @Input() showVersion = false;

  @Input() showSeparator = true;

  @Input() allowMovingFiles = true;

  @Input() maxCharacters = 110;

  @Output() movedFile = new EventEmitter<FileModel>();

  @Output() movedFiles = new EventEmitter<FileModel[]>();

  imageExtentions = [".bmp", ".png", ".jpg", ".jpeg", ".gif", ".tiff", ".svg"];

  public files: FileModelWithSize[];

  private isZipAll: boolean = false;

  constructor(public azureBlobService: AzureBlobService, public loadingService: LoadingService) {}

  async ngOnInit(): Promise<void> {
    this.isZipAll = !environment.production;
    this.files = this.fileList.map((item, i) => {
      console.log("File", item);
      const itemWithSize = item as FileModelWithSize;
      itemWithSize.Size = this.getMegabytes(item.Size || 0);

      return itemWithSize;
    });
  }

  getFileIcon(filename: string): string {
    const name = filename.toLowerCase();

    return this.imageExtentions.some((a) => name.includes(a))
      ? "icon icon-image_doc icon-cus_green"
      : "icon icon-blank_doc icon-cus_green";
  }

  moveFile(item: FileModel): void {
    // console.log(item);
    this.movedFile.emit(item);
  }

  moveFiles(): void {
    // console.log(item);
    this.movedFiles.emit(this.fileList);
  }

  async download(item: FileModel): Promise<void> {
    if (environment.fileService === "azure") {
      const body = await this.azureBlobService.downloadBlobToFile(item);
      const blob = await body.blobBody;
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = item.Name;
      link.click();
    } else {
      window.open(item.DownloadURL, "_blank");
    }
  }

  async preview(item: FileModel): Promise<void> {
    if (environment.fileService === "azure") {
      const url = await this.azureBlobService.generateURL(item);
      window.open(url, "_blank");
    } else {
      window.open(item.URL, "_blank");
    }
  }

  async downloadAll(): Promise<void> {
    if (environment.fileService === "azure") {
      this.loadingService.start();
      if(this.isZipAll)
      {
        const zip = new JSZip();
        // const blobs = await Promise.all(this.fileList.map(async (file) => {
        //   const blob = await this.azureBlobService.downloadBlobWithProgress(file);
        //   zip.file(file.Name, blob);
        // }));
        const blobs = await Promise.all(this.fileList.map(async (file) => {
          const res = await this.azureBlobService.downloadBlobToFile(file);
          const blob = await res.blobBody;
          zip.file(file.Name, blob);
        }));
        
        const zipBlob = await zip.generateAsync({ type: 'blob' },
          // function updateCallback(metadata) {
          //   const percent  = metadata.percent + '%';
          //   console.log(percent);
          // }
        );
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(zipBlob);
        link.download = "download.zip";
        link.click();
      } else {
        const blobs = await Promise.all(this.fileList.map(async (file) => {
          const res = await this.azureBlobService.downloadBlobToFile(file);
          return res.blobBody;
        }));
        let count = 0;
        for (let i = 0; i < blobs.length; i++) {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blobs[i]);
          link.download = this.fileList[i].Name;
          link.click();
          if (++count >= 10) {
            await this.sleep(2000);
            count = 0;
          }
        }
      }
      this.loadingService.stop();
    } else {
      this.fileList.forEach((item) => {
        window.open(item.DownloadURL, "_blank");
      });
    }
    // this.fileList.forEach((item) => {
    //   //window.open(item.DownloadURL, "_blank");
    //   const link = document.createElement("a");
    //   link.href = `${item.DownloadURL}&response-content-disposition=attachment`;
    //   link.download = item.Name;
    //   link.target = "_blank";
    //   link.style.display = 'none';
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // });
  }

  getFormatDate(dateString: string): string {
    const date = `${new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })} ${new Date(dateString).toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    })}`;

    return date;
  }

  getMegabytes(size): string {
    return `${(Math.round(+size / 1024) / 1024).toFixed(2)}MB`;
  }

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
