import { submittalAppId } from "@shared/staticValue";
import { IContracNestedViewItem } from "../api-generated";

export default class ContractCustomizedViewItem
  implements IContracNestedViewItem
{
  public UserId: string;
  public ContractId: string;
  public ApplicationId: string;
  public CustomizedView?: number | null;

  constructor(
    userId: string, contractId: string, customizedView?: number
  ) {
    this.UserId = userId;
    this.ContractId = contractId;
    this.ApplicationId = submittalAppId;
    this.CustomizedView = customizedView;
  }
}
