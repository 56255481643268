import { Component } from "@angular/core";
import LoadingService from "@services/loading.service";

@Component({
  selector: "app-access-denied",
  templateUrl: "./access-denied.component.html",
})
export default class AccessDeniedComponent {
  constructor(
      private loadingService: LoadingService,
    ) {}
  ngOnInit(): void {
    console.log("aaa");
    this.loadingService.stop();
  }
}
