import { v4 as uuidv4 } from 'uuid';
import { IPSubItem, ISubAcknowledgementStep } from "../api-generated";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalRejectDetails from "./submittalRejectDetail";
import SubmittalNotesBase from "./submittalNotesBase";
import SubmittalSelectBICBase from './submittalSelectBICBase';
import SubmittalCreateBase from './submittalCreateBase';
import { getLatestCreateItem } from '@shared/utils';

export default class SubmittalAcknowledgeQaqc
  extends SubmittalItemUpdateBase
  implements ISubAcknowledgementStep
{
  public submittal_create: SubmittalCreateBase;
  
  public submittal_select_bic: SubmittalSelectBICBase;

  public IsQAQCCompliance: boolean;

  public tempId?: string;
  
  public submittal_reject_detail?: SubmittalRejectDetails;
  
  public submittal_notes?: SubmittalNotesBase;

  public submittal_watcher: string[];

  constructor(contractId: string, item: IPSubItem) {
    super(contractId, item);
    this.submittal_create = new SubmittalCreateBase(getLatestCreateItem(item));
    this.IsQAQCCompliance = true;
    
    this.tempId = uuidv4();
    this.submittal_watcher =
      item.submittal_watcher?.map((i) => {
        return i.WatcherId ?? "";
      }) ?? [];
    this.submittal_reject_detail = new SubmittalRejectDetails();

    this.submittal_notes = new SubmittalNotesBase();
    this.submittal_select_bic = new SubmittalSelectBICBase();
  }
}
