import { FileModel, UploadStatus } from "@aecom/core";
import {
  BlockBlobClient,
  BlobDownloadResponseParsed,
} from "@azure/storage-blob";
import { Injectable } from "@angular/core";
import mime from "mime";
import { SubmittalDocumentsService } from "../api-generated";

@Injectable({
  providedIn: "root",
})
export default class AzureBlobService {

  constructor(public submittalDocumentService: SubmittalDocumentsService) {}

  static async uploadFile(url: string, file: FileModel): Promise<void> {
    const blockBlobClient = new BlockBlobClient(url);
    const type = mime.getType(file.Name) || "application/octet-stream";
    await blockBlobClient.uploadData(file.File, {
      blobHTTPHeaders: { blobContentType: type },
      onProgress: (ev) => {
        file.Percentage = ev.loadedBytes / file.File.size;
        file.Percentage = Math.floor(file.Percentage * 100);

        if (file.Percentage >= 100) {
          file.Status = UploadStatus.LOADED;
        }
      },
    });
  }

  async downloadBlobToFile(
    item: FileModel,
  ): Promise<BlobDownloadResponseParsed> {
    const url = await this.generateURL(item);
    const blobClient = new BlockBlobClient(url);
    return blobClient.download();
  }

  async generateURL(item: FileModel): Promise<string> {
    let lastSlashIndex = item.URL.lastIndexOf('/');
    const sub = item.URL.substring(0, lastSlashIndex);
    lastSlashIndex = sub.lastIndexOf('/');
    const guid = sub.substring(lastSlashIndex + 1);
    const url = await this.submittalDocumentService.generateURL({id: guid, name: item.Name}).toPromise();
    return url;
  }

  async downloadBlobWithProgress(item: FileModel): Promise<Blob> {
    const blobUrl = await this.generateURL(item);
    const response = await fetch(blobUrl);

    if (!response.ok) {
        throw new Error(`Failed to fetch blob: ${response.statusText}`);
    }

    const contentLength = response.headers.get('Content-Length');
    if (!contentLength) {
        console.warn('Content-Length header is missing, progress tracking may not work.');
    }

    const totalBytes = parseInt(contentLength, 10);
    let downloadedBytes = 0;

    const reader = response.body.getReader();
    const chunks = [];

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        downloadedBytes += value.length;
        chunks.push(value);

        // Display download progress
        const progress = ((downloadedBytes / totalBytes) * 100).toFixed(2);
        console.log(`Downloading... ${progress}%`);
    }

    console.log("Download complete!");

    // Combine chunks into a single blob
    const blob = new Blob(chunks);
    return blob;
  }
}

