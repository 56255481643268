import {
  FileModel,
  ModalContainerService,
  NotificationComponent,
  UploadStatus,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ComponentCanDeactivate } from "@guards/pending-changes.guard";
import SubmittalDocumentCopy from "@models/submittalDocumentCopy";
import SubmittalDocumentUpload from "@models/submittalDocumentUpload";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalResponse from "@models/submittalResponse";
import SubmittalStatus from "@models/submittalStatus";
import { ISubmittalViewResolver } from "@resolvers/view.resolver";
import AzureBlobService from "@services/azureBlob.service";
import BaseFunctionService from "@services/baseFunction.service";
import FileManagementService from "@services/fileManagement.service";
import LoadingService from "@services/loading.service";
import LocalContractUserService from "@services/local-contractUser.service";
import {
  getFilesByType,
  getVersionString,
  isFileNameInvalid,
} from "@shared/utils";
import _ from "lodash";
import { Observable } from "rxjs";
import {
  IPSubDocumentDownload,
  IPSubDocumentUploadReturn,
  IPSubItem,
  IPSubReviewer,
  SubmittalDocumentsService,
} from "src/app/api-generated";
import environment from "src/environments/environment";

@Component({
  selector: "app-response",
  templateUrl: "./response.component.html",
  styleUrls: ["./response.component.scss"],
})
export default class ResponseComponent
  implements OnInit, ComponentCanDeactivate
{
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  contractId: string;

  submittal: SubmittalResponse;

  oldSubmittal: SubmittalResponse;

  entity: IPSubItem;

  attemptToIssue = false;

  acknowledge = false;

  responseMaxLength = 2000;

  currentUser: string;

  attachments: FileModel[] = [];

  prevAttachments: FileModel[] = [];

  attachmentsNameValid = true;

  enableSaveDraft = false;

  defaultTab = "details";

  docs: IPSubDocumentDownload[] = [];
  isDBAdmin = false;

  private removedFileIds: string[] = [];

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public submittalDocumentService: SubmittalDocumentsService,
    public fileManagementService: FileManagementService,
    public localContractUserService: LocalContractUserService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    const data: ISubmittalViewResolver =
      this.activatedRoute.snapshot.data.submittalData;

    this.currentUser = data.currentUserId;
    this.contractId = data.submittal.ContractId;
    this.isDBAdmin = data.isDBAdmin;

    let oldId: string;

    this.entity = data.submittal;
    this.docs = data.documents;
    
    const myReviewItem: IPSubReviewer = this.entity.submittal_reviewer.find(
      (r) => {
        return (
          r.ReviewerId === this.currentUser &&
          r.SubmittalId === this.entity.Guid
        );
      },
    ) as IPSubReviewer;

    if (
      myReviewItem.submittal_reviewer_response &&
      myReviewItem.submittal_reviewer_response.length > 0
    ) {
      const currentResponse = myReviewItem.submittal_reviewer_response
        .sort((a, b) =>
          new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime(),
        ).find(
          (item) => {
            // return (
            //   item.Submittal_version ===
            //     getVersionString(submittal.SubmittalNumber) && item.IsDraft
            // );
            return item.Submittal_version === getVersionString(this.entity.SubmittalNumber);
          },
      );

      if (currentResponse) {
        oldId = currentResponse.Guid;
        this.oldSubmittal = new SubmittalResponse(
          this.entity,
          myReviewItem,
          currentResponse,
        );
      } else {
        this.oldSubmittal = new SubmittalResponse(this.entity, myReviewItem);
      }
    } else {
      this.oldSubmittal = new SubmittalResponse(this.entity, myReviewItem);
    }

    if (oldId && this.docs && this.docs.length > 0) {
      const documnetsForThis: IPSubDocumentDownload[] = this.docs.filter((item) => {
        return item.ReferenceId === oldId;
      });

      this.attachments = getFilesByType(
        documnetsForThis,
        SubmittalFileType.Response,
      );

      if(this.oldSubmittal.tempId)
      {
        this.attachments.forEach((doc)=>{
          const fileUploaded = new SubmittalDocumentCopy(
            SubmittalFileType.Response,
            this.oldSubmittal.Submittal_version,
            SubmittalStatus.Compiling,
            this.oldSubmittal.SubmittalId,
            this.oldSubmittal.tempId,
            doc.Guid,
          );
          this.submittalDocumentService.copySubmittalDocument(fileUploaded).subscribe((res) => {
            doc.Guid = res.Guid;
          });
        })
      }
      this.prevAttachments = [...this.attachments];
    }

    this.submittal = _.cloneDeep(this.oldSubmittal);
    this.loadingService.stop();
  }

  get submittalFileType() {
    return SubmittalFileType;
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldSubmittal);
    const current = JSON.stringify(this.submittal);
    const oldAttachment = JSON.stringify(this.prevAttachments);
    const currentAttachment = JSON.stringify(this.attachments);
    return old !== current || oldAttachment !== currentAttachment;
  }

  IsInputValid(): boolean {
    return this.getResponseLength() <= this.responseMaxLength &&
    !this.baseFunctionService.isInputInvalid(this.submittal.Response);
  }

  IsValid(): boolean {
    if (this.submittal.hasComments && (
      !this.submittal.Response ||
      this.submittal.Response.trim() === "" ||
      // !this.acknowledge ||
      !this.isFileNameValid()
    )) {
      return false;
    }
    return true;
  }

  submit(): void {
    this.attemptToIssue = true;

    const validation = this.IsValid() && this.IsInputValid();
    if (!validation) {
      return;
    }

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Submit Submittal?";
      modalInstance.instance.body =
        "Your Response will be sent to the Coordinator.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.submittal.IsDraft = false;
          this.update();
        }
      });
    }
  }

  saveDraft(): void {
    if (!this.hasChanges()||!this.isFileNameValid()||!this.IsInputValid()) return;

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Save as Draft?";
      modalInstance.instance.body = "Your Submittal will save as draft.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.submittal.IsDraft = true;
          this.update();
        }
      });
    }
  }

  update(): void {
    this.loadingService.start();
    if(!this.submittal.hasComments)
    {
      this.submittal.Response = "";
    }
    this.updateFiles();
    this.baseFunctionService.submitMyReviewResponse(this.submittal);
  }

  updateFiles(): void {
    const prevAttachments = this.prevAttachments.map((f) => f.Guid);

    const filesToAdd = this.attachments.filter((f) => {
      return (
        !prevAttachments.includes(f.Guid) && f.Status === UploadStatus.LOADED
      );
    }).map((f) => {
      return f.Guid;
    });

    const fileIds = this.attachments.map((f) => {
      return f.Guid;
    });

    const filesToRemove = prevAttachments.filter((f) => {
      return !fileIds.includes(f);
    });

    const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];
    if(this.submittal.hasComments)
    {
      this.submittal.docSave = filesToAdd;
      this.submittal.docRemove = removeIds;
    } else {
      this.submittal.docRemove = [...new Set([...removeIds, ...fileIds])];
    }
  }

  setHasComments(e: boolean): void {
    this.submittal.hasComments = e;
    
  }

  setResponse(e: string): void {
    this.submittal.Response = e;
    
  }

  setAcknowledge(e: boolean): void {
    this.acknowledge = e;
    
  }

  importFile(e: FileModel[]): void {
    if (!_.isEmpty(e)) {
      // console.log(type);
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new SubmittalDocumentUpload(
            item.Name,
            this.currentUser,
            SubmittalFileType.Response,
            this.submittal.Submittal_version,
            SubmittalStatus.Compiling,
            this.submittal.SubmittalId,
            this.submittal.Guid && this.submittal.Guid !== ""
              ? this.submittal.Guid
              : this.submittal.tempId,
          );
          await this.submittalDocumentService
            .createSubmittalDocument(fileUploaded)
            .toPromise()
            .then((r: IPSubDocumentUploadReturn) => {
              item.Guid = r.Guid;
              this.updateFileList(item);
              
              if (environment.fileService === "azure") {
                AzureBlobService.uploadFile(r.URL, item);
              } else {
                this.fileManagementService.uploadFileToS3(r.URL, item);
              }
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item);
        }
      });
    }
  }

  deleteFile(e: FileModel): void {
    this.updateFileList(e, true);
    
  }

  addFileToAttachment(prevItem: FileModel): void {
    const item = _.cloneDeep(prevItem);
    item.Percentage = 100;
    item.Status = "preloaded";
    if (!this.isFileExist(prevItem)) {
      const fileUploaded = new SubmittalDocumentCopy(
        SubmittalFileType.Response,
        this.submittal.Submittal_version,
        SubmittalStatus.Compiling,
        this.submittal.SubmittalId,
        this.submittal.Guid && this.submittal.Guid !== ""
          ? this.submittal.Guid
          : this.submittal.tempId,
        item.Guid,
      );

      this.submittalDocumentService
        .copySubmittalDocument(fileUploaded)
        .subscribe((res) => {
          item.Guid = res.Guid;
          this.updateFileList(item);
          
        });
    }
  }

  addFilesToAttachments(prevItems: FileModel[]): void {
    const items = _.cloneDeep(prevItems);
    items.forEach((item) => {
      this.addFileToAttachment(item);
    });
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  getResponseLength(): number {
    return this.submittal.Response ? this.submittal.Response.length : 0;
  }
  updateFileList(item: FileModel, isRemove = false): void {
    const index = this.attachments.findIndex((file) => {
      return file.Name === item.Name;
    });
    if (isRemove) {
      if (index !== -1) {
        this.attachments.splice(index, 1);
      }
    } else {
      if (index === -1) {
        this.attachments.push(item);
        this.attachments = this.attachments.slice();
      }
    }
    if (isRemove) {
      this.removedFileIds.push(item.Guid);
    }
    this.isFileNameValid();
  }

  isFileNameValid(): boolean {
    this.attachmentsNameValid = true;
    this.attachments.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.attachmentsNameValid = false;
      }
    });
    return this.attachmentsNameValid;
  }

  isFileExist(item: FileModel): boolean {
    const index = this.attachments.findIndex((file) => {
      return file.Name === item.Name;
    });
    return index > -1;
  }

  hasFilesUploading(): boolean {
    return this.attachments.some(
      (item) => item.Status === UploadStatus.UPLOADING,
    );
  }

  showFileProgressNotification(): void {
    this.baseFunctionService.showFileProgressNotification();
  }
}
