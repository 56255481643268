<div class="container-fluid" *ngIf="submittal">
  <div class="row">
    <div class="col-12">
      <div class="page-header">
        <div class="col-6" style="padding: 0; display: flex">
          <div class="header-title">
            <p>
              <a [routerLink]="['/' + submittal.ContractId + '/list']"> Submittal List &gt; </a>
              <span> {{ submittal.Guid ? submittal.SubmittalNumber : "New Submittal" }}</span>
            </p>

            <h1>
              <i class="icon icon-lg icon-pencil"></i>

              <span style="text-transform: uppercase">{{ title }}</span>
            </h1>
          </div>
          <div
            style="flex: 1; display: flex; align-items: flex-end; padding-right: 40px"
            *ngIf="IsRevise || IsRevision"
          >
            <div class="header-subTitle" style="margin-left: auto">{{ submittal.SubmittalNumber }}</div>
          </div>
        </div>

        <div class="col-6" style="padding: 0">
          <div class="header-actions">
            <aecom-button data-cy="back" innerClass="btn lg outline orange" (click)="back()"> back </aecom-button>
            <aecom-button
              *ngIf="showVoid && !isSubmittedStep"
              innerClass="btn lg outline orange"
              (click)="voidSubmittal()"
            >
              void
            </aecom-button>
            <aecom-button
              *ngIf="!isSubmittedStep"
              [theme]="'light'"
              [disabled]="!hasChanges() ? true : null"
              innerClass="btn lg solid green"
              (click)="saveDraft()"
            >
              save draft
            </aecom-button>

            <aecom-button data-cy="submit" [theme]="'light'" [disabled]="isSubmittedStep && !hasChanges() ? true : null" innerClass="btn lg solid blue" (click)="submit()"> submit </aecom-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <aecom-form columns="12">
        <div style="grid-column: span 6 / auto; position: relative">
          <aecom-form-group
            title="DETAILS"
            columnSpan="6"
            columns="6"
            style="display: block; margin-bottom: 10px; min-height: 60%"
          >
            <aecom-form-item columnSpan="3" [label]="'Internal Reference Number'" [property]="getReferenceNumberLength() + '/' + referenceNumberMaxLength + ' characters'">
              <aecom-textbox
              data-cy="reference"
                [theme]="'light'"
                [(ngValue)]="submittal.submittal_create.ReferenceNumber"
                (ngValueChange)="setReference($event)"
                [maxlength]="referenceNumberMaxLength"
                [validate]="
                  getReferenceNumberLength() > referenceNumberMaxLength
                  || baseFunctionService.isInputInvalid(submittal.submittal_create.ReferenceNumber)
                "
              ></aecom-textbox>
            </aecom-form-item>
            <aecom-form-item
              columnSpan="6"
              [label]="'Title*'"
              [property]="getTitleLength() + '/' + titleMaxLength + ' characters'"
            >
              <bbj-autoPopulateTextbox
                data-cy="title"
                [theme]="'light'"
                [notEmpty]="true"
                [maxlength]="titleMaxLength"
                [(value)]="submittal.submittal_create.Title" 
                [allValues]="titles"
                (valueChange)="setTitle($event)"
                [validate]="
                  (attemptToIssue && !submittal.submittal_create.Title?.length) 
                  || getTitleLength() > titleMaxLength
                  || baseFunctionService.isInputInvalid(submittal.submittal_create.Title)
                "
              ></bbj-autoPopulateTextbox>
            </aecom-form-item>
            <!-- <aecom-form-item
              columnSpan="6"
              [label]="'Title*'"
              [property]="getTitleLength() + '/' + titleMaxLength + ' characters'"
            >
              <aecom-textbox
                data-cy="title"
                [theme]="'light'"
                [notEmpty]="true"
                [maxlength]="titleMaxLength"
                [(ngValue)]="submittal.submittal_create.Title"
                (ngValueChange)="setTitle($event)"
                [validate]="
                  (attemptToIssue && !submittal.submittal_create.Title?.length) 
                  || getTitleLength() > titleMaxLength
                  || baseFunctionService.isInputInvalid(submittal.submittal_create.Title)
                "
              ></aecom-textbox>
            </aecom-form-item> -->

            <aecom-form-item columnSpan="3" [label]="'Cover Sheet*'">
              <aecom-upload
                data-cy="coverSheet"
                class="h237"
                [isNewStyle]="true"
                [multiFiles]="true"
                [canDelete]="true"
                [data]="coverSheet"
                [theme]="'light'"
                [disabled]="isSubmittedStep"
                (ngValueChange)="importFile($event, submittalFileType.CoverSheet)"
                (ngDeleteChange)="deleteFile($event, submittalFileType.CoverSheet)"
              ></aecom-upload>
              <div class="error-message" *ngIf="coverSheet.length === 0 && attemptToIssue">Required Field</div>
              <div class="error-message" *ngIf="!coverSheetNameValid">
                File Name cannot include special characters other than '-', '_', '.', '@'
              </div>
            </aecom-form-item>

            <aecom-form-item columnSpan="3" [label]="'Attachments*'">
              <aecom-upload
                data-cy="submittalDocuments"
                class="h237"
                [isNewStyle]="true"
                [multiFiles]="true"
                [canDelete]="true"
                [data]="submittalDocuments"
                [theme]="'light'"
                [disabled]="isSubmittedStep"
                (ngValueChange)="importFile($event, submittalFileType.SubmittalDocuments)"
                (ngDeleteChange)="deleteFile($event, submittalFileType.SubmittalDocuments)"
              ></aecom-upload>
              <div class="error-message" *ngIf="submittalDocuments.length === 0 && attemptToIssue">Required Field</div>
              <div class="error-message" *ngIf="!submittalDocumentsNameValid">
                File Name cannot include special characters other than '-', '_', '.', '@'
              </div>
            </aecom-form-item>

            <aecom-form-item
              columnSpan="6"
              [label]="'BIM File Link'"
            >
              <aecom-textbox
                data-cy="bim360"
                [theme]="'light'"
                placeholder="URL"
                [(ngValue)]="submittal.submittal_create.BIM360"
                (ngValueChange)="setBIM360($event)"
                [validate]="baseFunctionService.isInputInvalid(submittal.submittal_create.BIM360)"
              ></aecom-textbox>
            </aecom-form-item>
          </aecom-form-group>
          
        </div>
        <div style="grid-column: span 3 / auto">
          <aecom-form-group title="Metadata">
            <aecom-form-item [label]="'Review Type*'" columnSpan="3">
              <aecom-dropdown
              data-cy="submittalType"
                [theme]="'light'"
                placeholder="Select"
                [data]="submittalTypeList"
                [selectedId]="submittal.submittal_create.SubmittalTypeId"
                [validate]="attemptToIssue && !submittal.submittal_create.SubmittalTypeId"
                (ngValueChange)="setSubmittalTypeList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
            <aecom-form-item [label]="'Drawing/Page Count*'" columnSpan="3">
              <aecom-dropdown
              data-cy="page"
                [theme]="'light'"
                placeholder="Select"
                [data]="drawingPageList"
                [selectedId]="submittal.submittal_create.DrawingPageId"
                [validate]="attemptToIssue && !submittal.submittal_create.DrawingPageId"
                (ngValueChange)="setDrawingPageList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
            <aecom-form-item *ngIf="showPhaseAndContractRequirement" [label]="'Phase*'" columnSpan="3">
              <aecom-dropdown
              data-cy="phase"
                [theme]="'light'"
                placeholder="Select"
                [data]="phaseList"
                [selectedId]="submittal.submittal_create.PhaseId"
                [validate]="attemptToIssue && !submittal.submittal_create.PhaseId"
                (ngValueChange)="setPhaseList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
            <aecom-form-item [label]="'Document Type*'" columnSpan="3">
              <aecom-dropdown
              data-cy="documentType"
                [theme]="'light'"
                placeholder="Select"
                [data]="documentTypeList"
                [autocomplete]="true"
                [selectedId]="submittal.submittal_create.DocumentTypeId"
                [validate]="attemptToIssue && !submittal.submittal_create.DocumentTypeId"
                (ngValueChange)="setDocumentTypeList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
            <aecom-form-item *ngIf="showPhaseAndContractRequirement" [label]="'Contract Requirements (DBA/GPR/SPR)*'" columnSpan="3">
              <aecom-dropdown
              data-cy="contractRequirement"
                [theme]="'light'"
                placeholder="Select"
                [data]="contractRequirementList"
                [autocomplete]="true"
                [selectedId]="submittal.submittal_create.ContractRequirementId"
                [validate]="attemptToIssue && !submittal.submittal_create.ContractRequirementId"
                (ngValueChange)="setContractRequirementList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
            <aecom-form-item [label]="requireUniFormatCategory ? 'UniFormat Level 1 Category*' : 'UniFormat Level 1 Category'" columnSpan="3">
              <aecom-dropdown
              data-cy="division"
                [theme]="'light'"
                placeholder="Select"
                [data]="divisionList"
                [selectedId]="division"
                [validate]="attemptToIssue && !division && requireUniFormatCategory"
                (ngValueChange)="setDivisionList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
            <aecom-form-item [label]="requireUniFormatCategory ? 'UniFormat Level 2 Category*' : 'UniFormat Level 2 Category'" columnSpan="3">
              <aecom-dropdown
              data-cy="specification"
                [theme]="'light'"
                placeholder="Select"
                [data]="specificationCleanList"
                [autocomplete]="true"
                [selectedId]="submittal.submittal_create.SpecificationId"
                [validate]="attemptToIssue && !submittal.submittal_create.SpecificationId && requireUniFormatCategory"
                (ngValueChange)="setSpecificationList($event)"
                (searchedValue)="setSpecificationList($event)"
                (change)="setSpecificationList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
            <aecom-form-item [label]="'MasterFormat Specification Section (6-digit)'" columnSpan="3">
              <aecom-dropdown
                data-cy="MasterSpec"
                  [theme]="'light'"
                  placeholder="Select"
                  [data]="masterSpecList"
                  [autocomplete]="true"
                  [selectedId]="submittal.submittal_create.MasterSpecId"
                  (ngValueChange)="setMasterSpecificationList($event)"
                  (searchedValue)="setMasterSpecificationList($event)"
                  (change)="setMasterSpecificationList($event)"
                ></aecom-dropdown>
            </aecom-form-item> 
            
          </aecom-form-group>
        </div>
        <div style="grid-column: span 3 / auto">
          <aecom-form-group title="Additional Details">
            <aecom-form-item [label]="'Is this submittal confidential?'" columnSpan="3">
              <div class="button-wrapper d-flex">
                <aecom-button
                  innerClass="btn solid btn-tab {{ submittal.submittal_create.IsConfidentail ? 'blue' : 'bg-grey-e' }}"
                  (click)="setIsConfidential(true)"
                >
                  Yes
                </aecom-button>
                <aecom-button
                  innerClass="btn solid btn-tab {{ !submittal.submittal_create.IsConfidentail ? 'blue' : 'bg-grey-e' }}"
                  (click)="setIsConfidential(false)"
                >
                No
                </aecom-button>
              </div>
            </aecom-form-item>
            <aecom-form-item [label]="'Priority'" columnSpan="3" data-cy="priority">
              <div class="button-wrapper d-flex">
                <aecom-button
                  innerClass="btn solid btn-tab {{ submittal.submittal_create.Priority === 1 ? 'blue' : 'bg-grey-e' }}"
                  (click)="setPriority(1)"
                >
                  Low
                </aecom-button>
  
                <aecom-button
                  innerClass="btn solid btn-tab {{ submittal.submittal_create.Priority === 2 ? 'blue' : 'bg-grey-e' }}"
                  (click)="setPriority(2)"
                >
                  Medium
                </aecom-button>
  
                <aecom-button
                  innerClass="btn solid btn-tab {{ submittal.submittal_create.Priority === 3 ? 'blue' : 'bg-grey-e' }}"
                  (click)="setPriority(3)"
                >
                  High
                </aecom-button>
              </div>
            </aecom-form-item>
            <aecom-form-item
              [property]="getPriorityNoteLength() + '/' + priorityNoteMaxLength + ' characters'"
              columnSpan="3"
              class="formItemNoMargin"
              *ngIf="submittal.submittal_create.Priority === 3"
            >
              <aecom-textbox
              data-cy="note"
                rows="5"
                [theme]="'light'"
                [notEmpty]="true"
                [maxlength]="priorityNoteMaxLength"
                [isMultiLine]="true"
                [(ngValue)]="submittal.submittal_create.PriorityReason"
                [validate]="
                  (attemptToIssue && !submittal.submittal_create.PriorityReason?.length) 
                  || getPriorityNoteLength() > priorityNoteMaxLength
                  || baseFunctionService.isInputInvalid(submittal.submittal_create.PriorityReason)
                "
                placeholder="Please enter reason here:"
              ></aecom-textbox>
            </aecom-form-item>
            <aecom-form-item
              [label]="'Processing Note'"
              [property]="getReferenceNoteLength() + '/' + referenceNoteMaxLength + ' characters'"
              columnSpan="3"
            >
              <aecom-textbox
                rows="5"
                [theme]="'light'"
                [notEmpty]="true"
                [maxlength]="referenceNoteMaxLength"
                [isMultiLine]="true"
                [(ngValue)]="submittal.submittal_create.ReferenceNote"
                (ngValueChange)="setNote($event)"
                [validate]="getReferenceNoteLength() > referenceNoteMaxLength
                || baseFunctionService.isInputInvalid(submittal.submittal_create.ReferenceNote)"
              ></aecom-textbox>
            </aecom-form-item>
          </aecom-form-group>
        </div>
      </aecom-form>
    </div>
  </div>
</div>
