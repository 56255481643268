/* eslint-disable prefer-destructuring */
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Params,
} from "@angular/router";
import LoadingService from "@services/loading.service";
import { catchError, map, Observable, of } from "rxjs";
import BluebeamService from "@services/bluebeam.service";

@Injectable({
  providedIn: "root",
})
export default class CallBackResolver
{
  constructor(
    private loadingService: LoadingService,
    public bluebeamService: BluebeamService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<void> {
    this.loadingService.start();
    const params: Params = route.queryParams;
    return this.bluebeamService.getResponse(params).pipe(
      map((res)=>{
        const redirectUrl = this.bluebeamService.getCallbackUrl();
        if(res && redirectUrl)
        {
          window.location.href = redirectUrl;
        }
        return;
      }),
      catchError((error) => of(console.log(error))),
    );
  }
}
