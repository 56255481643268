import { Injectable } from "@angular/core";
import { IPSubListItem } from "../api-generated";
import BaseService from "./base.service";

@Injectable({
  providedIn: "root",
})
export default class LocalSubmittalItemService extends BaseService<IPSubListItem> {
  public firstLoad = false;
  public customizedView: number = 0;
}
