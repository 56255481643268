import FileModelWithVersion from "@models/fileModelWithVersion";
import SubmittalCompileBase from "@models/submittalCompileBase";
import SubmittalFileType from "@models/submittalFileType";
import LocalContractUserService from "@services/local-contractUser.service";
import { getDispositionText, getFilesByType, getLatestCreateItem, getWorkflowStatusArray } from "@shared/utils";
import {
  IPSubDocumentDownload,
  IPSubItem,
  IUser,
  SubmittalDisposition,
} from "../api-generated";

export default class DispositionListItem extends SubmittalCompileBase {
  public Name: string;

  public Organization: string;

  public Department: string;

  public RevisionNumber: string;

  public FormatedDateUpdated: string;

  public DispositionText: string;

  public Collapse: boolean;

  public CoverSheet?: FileModelWithVersion[];

  public CommentResolutionForm?: FileModelWithVersion[];

  public MarkedUpComments?: FileModelWithVersion[];

  constructor(
    submittal: IPSubItem,
    item: SubmittalDisposition,
    documents: IPSubDocumentDownload[],
    localContractUserService: LocalContractUserService,
  ) {
    super(1, item);

    let updateUser: IUser;

    if (item.UpdatedBy) {
      updateUser = localContractUserService.getUserById(
        item.UpdatedBy,
      )?.UserInfo;
      
    }

    this.Name = updateUser?.displayName ?? "";
      this.Organization = updateUser?.companyName ?? "[Organization]";
      this.Department = updateUser?.department ?? "[Department]";

    this.RevisionNumber = item.Submittal_version;

    this.Collapse = true;

    this.FormatedDateUpdated = item.DateUpdated
      ? `${new Date(item.DateUpdated).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })} ${new Date(item.DateUpdated).toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
        })}`
      : "-";

    this.DispositionText = getDispositionText(item.Disposition, getLatestCreateItem(submittal)?.SubmittalTypeId);

    const documnetsForThis = documents.filter((doc) => {
      return doc.ReferenceId === item.Guid;
    });

    this.CoverSheet = getFilesByType(
      documnetsForThis,
      SubmittalFileType.CoverSheetStampSigned,
    );

    this.CommentResolutionForm = getFilesByType(
      documnetsForThis,
      SubmittalFileType.CommentResolutionForm,
    );

    this.MarkedUpComments = getFilesByType(
      documnetsForThis,
      SubmittalFileType.MarkedUpComments,
    );
  }
}
