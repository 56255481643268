import { canRevise, getLatestCreateItem } from "@shared/utils";
import { v4 as uuidv4 } from 'uuid';
import { IPSubItem } from "../api-generated";
import { ISubCreateStep } from "../api-generated/model/iSubCreateStep";
import SubmittalCreateBase from "./submittalCreateBase";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalDueDateDetails from "./submittalDueDateDetail";
import SubmittalStatus from "./submittalStatus";

export default class SubmittalCreate
  extends SubmittalItemUpdateBase
  implements ISubCreateStep
{
  public submittal_create: SubmittalCreateBase;

  public tempId?: string;

  public submittal_duedate?: SubmittalDueDateDetails;

  constructor(contractId: string, item?: IPSubItem) {
    super(contractId, item);

    this.submittal_create = new SubmittalCreateBase(getLatestCreateItem(item));

    if(item?.Status === SubmittalStatus.Submitted || 
      (item?.submittal_create && item.submittal_create.length > 1 && this.submittal_create.IsDraft !== true)
    )
    {
      delete this.submittal_create.Guid;
      delete this.submittal_create.CreatedBy;
      delete this.submittal_create.DateCreated;
      delete this.submittal_create.UpdatedBy;
      delete this.submittal_create.DateUpdated;
    }
    
    this.submittal_duedate = new SubmittalDueDateDetails();

    if (!item || canRevise(item.Status)) {
      this.tempId = uuidv4();
    }
  }
}
