import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import LoadingService from "../services/loading.service";
import LocalContractUserService from "../services/local-contractUser.service";
import RouteParams from "../shared/route-params";
import { SubmittalService } from "../api-generated";
import SubmittalStatus from "@models/submittalStatus";
import LocalSubmittalItemService from "@services/local-submittalItem.service";
import { HttpErrorResponse } from "@angular/common/http";
import BaseFunctionService from "@services/baseFunction.service";

@Injectable({
  providedIn: "root"
})
export default class ViewAndNavResolver {
  constructor(
    public router: Router,
    private loadingService: LoadingService,
    private submittalService: SubmittalService,
    public localContractUserSerice: LocalContractUserService,
    public localSubmittalItemService: LocalSubmittalItemService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<null | undefined> {
    this.loadingService.start();
    this.localSubmittalItemService.firstLoad = false;
    const itemId = route.params[RouteParams.ItemId];
    const contractId = route.parent.parent.params[RouteParams.ContractId];
    if(state.url === `/${contractId}/0/create/new` || state.url === `/${contractId}/0/createNew/new`)
    {
      return of(undefined);
    }

    return this.submittalService.getSubmittalItem(itemId).pipe(
      map((item) => {
        const arr = [SubmittalStatus.Accepted_With_Comments, SubmittalStatus.Draft, SubmittalStatus.Reviewed_With_Comments, SubmittalStatus.Revise_Resubmit, SubmittalStatus.QAQC_Fail, SubmittalStatus.Denied, SubmittalStatus.Submitted];
        if(!arr.some((a)=>{return a===item.Status}))
        {
          this.router.navigateByUrl(`/${contractId}/0/view/${itemId}`);
        }
        this.baseFunctionService.setEntity(item);
        this.loadingService.stop();
        return null;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        this.router.navigate([contractId, "error", error.status ? error.status : 500]);
        return of(null);
      }),
    );
  }
}
