import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ExternalLoggingService, IAPIRequestLog } from '../api-generated';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '@bbj/components';

@Injectable({
  providedIn: 'root',
})
export class HttpLogService {
  constructor(private http: HttpClient, private injector: Injector) {}

  get(key: string, url: string, options?: any & { headers?: HttpHeaders }): Observable<any> {
    return this.http
      .get(url, options)
      .pipe(
        tap((response)=>this.startTime(key,url,'GET', options?.headers? JSON.stringify(options.headers) : null, null, JSON.stringify(response),null)),
        catchError((error)=>this.startTime(key,url,'GET', options?.headers? JSON.stringify(options.headers) : null, null,null, JSON.stringify(error)))
      );
  }

  post(key: string, url: string, body: any | null, bodyText: string | null, options?: any & { headers?: HttpHeaders }): Observable<any> {
    return this.http
      .post(url, body, options)
      .pipe(
        tap((response)=>this.startTime(key,url,'POST', options?.headers? JSON.stringify(options.headers) : null, bodyText, JSON.stringify(response),null)),
        catchError((error)=>this.startTime(key,url,'POST', options?.headers? JSON.stringify(options.headers) : null, bodyText,null, JSON.stringify(error)))
      );
  }

  put(key: string, url: string, body: any | null, bodyText: string | null, options?: any & { headers?: HttpHeaders }): Observable<any> {
    return this.http
      .put(url, body, options)
      .pipe(
        tap((response)=>this.startTime(key,url,'PUT', options?.headers? JSON.stringify(options.headers) : null, bodyText, JSON.stringify(response),null)),
        catchError((error)=>this.startTime(key,url,'PUT', options?.headers? JSON.stringify(options.headers) : null, bodyText,null, JSON.stringify(error)))
      );
  }

  patch(key: string, url: string, body: any | null, bodyText: string | null, options?: any  & { headers?: HttpHeaders }): Observable<any> {
    return this.http
      .patch(url, body, options)
      .pipe(
        tap((response)=>this.startTime(key,url,'PATCH', options?.headers? JSON.stringify(options.headers) : null, bodyText, JSON.stringify(response),null)),
        catchError((error)=>this.startTime(key,url,'PATCH', options?.headers? JSON.stringify(options.headers) : null, bodyText,null, JSON.stringify(error)))
      );
  }

  delete(key: string, url: string, options?: any & { headers?: HttpHeaders }): Observable<any> {
    return this.http
      .delete(url, options)
      .pipe(
        tap((response)=>this.startTime(key,url,'DELETE', options?.headers? JSON.stringify(options.headers) : null, null, JSON.stringify(response),null)),
        catchError((error)=>this.startTime(key,url,'DELETE', options?.headers? JSON.stringify(options.headers) : null, null,null, JSON.stringify(error)))
      );
  }

  private startTime(key: string, url: string, method: string, header: string | null, body: string | null, response: string | null, error: string | null) {
    const authService: AuthService = this.injector.get(AuthService);
    const logService: ExternalLoggingService = this.injector.get(ExternalLoggingService);
    const currentDate = new Date().toUTCString();
    const parameter: IAPIRequestLog = {
        module: "Submittal",
        userId: authService.getUserId(),
        apiKey: key,
        apiEndpoint: url,
        apiMethod: method,
        elapsedTime: currentDate,
        url: location.href,
        requestBody: body ?? null,
        requestHeader: header ?? undefined,
        response: response ?? undefined,
        error: error ?? undefined
    };
    return logService.apiCallLog(parameter);
  }
}