import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IBluebeamCreateInput, IBluebeamGetUsersRes, IBluebeamInitSessionRes, IBluebeamSessionCreateFileBlockRes, ICreateSessionFileBlockRes } from "@models/Ibluebeam";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import environment from "src/environments/environment";
import { BluebeamWebService, IBluebeamGrantAccessParam, IBluebeamGrantAccessRes, IPSubItem } from "../api-generated";
import { setOffsetDate } from "@shared/utils";
import { HttpLogService } from "./httpLog.service";
import { Params } from "@angular/router";
import SubmittalBluebeamStep from "@models/submittalBluebeamStep";

@Injectable({
  providedIn: "root",
})
export default class BluebeamService {
  private serviceKey = "Bluebeam";

  private authUrl = "https://api.bluebeam.com/oauth2/authorize";

  private sessionUrl = "https://api.bluebeam.com/publicapi/v1/sessions";

  private key_response_type = "response_type";

  private key_client_id = "client_id";

  private key_redirect_uri = "redirect_uri";

  private key_scope = "scope";

  private key_state = "state";

  private value_response_type = "code";

  private value_client_id = environment.bluebeam.clientId;

  private value_redirect_url = environment.url.callBackUri;

  private value_scope = "full_user jobs offline_access";

  private value_state = "myteststate";

  private bluebeamToken = "bluebeamToken";

  private bluebeamCallbackRedirectUrl = "bluebeamRedirect";

  private storedData = "storedData";

  private message = "You have been assigned a submittal for review. Please use the Bluebeam session to review the documents and add your comments.";

  constructor(private httpSrv: HttpLogService, private bluebeamWebServices: BluebeamWebService) {}

  public getResponse(params: Params): Observable<IBluebeamGrantAccessRes> {
    
    const paramExp: IBluebeamGrantAccessParam = {
      code: params["code"],
      state: params["state"],
      scope: this.value_scope,
      redirect_uri: this.value_redirect_url
    };

    return this.bluebeamWebServices.getAuthToken(paramExp)
      .pipe(
        tap(console.log),
        map((res: IBluebeamGrantAccessRes)=>{
          const now = new Date().getTime();
          res.expires_in = now + parseInt(res.expires_in.toString())*1000;
          sessionStorage.setItem(this.bluebeamToken, JSON.stringify(res));
          return res;
        }),
        catchError(this.handleError)
      );
  }

  public getCallbackUrl(): string | null {
    const url = sessionStorage.getItem(this.bluebeamCallbackRedirectUrl);
    return url ?? null;
  }

  public bluebeamLogin(): void {
    const url = `${this.authUrl}?${this.key_response_type}=${this.value_response_type}&${this.key_client_id}=${this.value_client_id}&${this.key_redirect_uri}=${this.value_redirect_url}&${this.key_scope}=${this.value_scope}&${this.key_state}=${this.value_state}`;
    sessionStorage.setItem(this.bluebeamCallbackRedirectUrl, window.location.href);
    window.location.href = url;
  }

  public getRefreshToken(refreshToken: string): Observable<IBluebeamGrantAccessRes> {
    return this.bluebeamWebServices.getRefreshToken({refreshToken})
      .pipe(
        tap(console.log),
        map((res: IBluebeamGrantAccessRes)=>{
          const now = new Date().getTime();
          res.expires_in = now + parseInt(res.expires_in.toString())*1000;
          sessionStorage.setItem(this.bluebeamToken, JSON.stringify(res));
          return res;
        }),
        catchError(this.handleError)
      );
  }

  public getStoredToken(): IBluebeamGrantAccessRes | null {
    const bt = sessionStorage.getItem(this.bluebeamToken);
    if(bt)
    {
      const accessRes = JSON.parse(bt) as IBluebeamGrantAccessRes;
      return accessRes;
    }
    return null;
  }

  public getValidAccessToken(): Observable<string | null> {
    const bt = this.getStoredToken();
    if(bt)
    {
      const now = new Date().getTime();
      if(bt.expires_in > now)
      {
        return of(`${bt.token_type} ${bt.access_token}`);
      }
      else {
        return this.getRefreshToken(bt.refresh_token).pipe(map((token)=>`${token.token_type} ${token.access_token}`))
      }
    }
    return of(null);
  }

  public setStoredData(data: unknown): void {
    sessionStorage.setItem(this.storedData, JSON.stringify(data));
  }

  public getStoredData(): unknown {
    const data = sessionStorage.getItem(this.storedData);
    if(data)
    {
      return JSON.parse(data);
    }
    return null;
  }

  public removeStoredData(): void {
    sessionStorage.removeItem(this.storedData);
    sessionStorage.removeItem(this.bluebeamCallbackRedirectUrl);
  }

  public initSession(name: string, due: Date): Observable<IBluebeamInitSessionRes> {
    const url = this.sessionUrl;
    const body = { 
      Name: name,
      Notification: true,
      Restricted: true,
      SessionEndDate: due.toISOString(),
      DefaultPermissions: [
        { Type: "SaveCopy", Allow: "Allow" },
        { Type: "PrintCopy", Allow: "Allow" },
        { Type: "Markup", Allow: "Allow" },
        { Type: "MarkupAlert", Allow: "Allow" },
        { Type: "AddDocuments", Allow: "Allow" },
      ]
    };
    return this.getValidAccessToken()
      .pipe(
        switchMap((token)=>{
          if(token)
          {
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token).set('client_id', this.value_client_id);
            return this.httpSrv.post(this.serviceKey, url, body, JSON.stringify(body), {headers});
          }
        }),
        tap(console.log),
        catchError(this.handleError)
      );
  }

  public createSessionFileBlock(sessionId: string, file: IBluebeamCreateInput): Observable<ICreateSessionFileBlockRes | null> {
    
    const url = `${this.sessionUrl}/${sessionId}/files`;
    const body = { 
      Name: file.Name,
      Source: file.DownloadURL
    };
    return this.getValidAccessToken()
      .pipe(
        switchMap((token)=>{
          if(token)
          {
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token).set('client_id', this.value_client_id);
            return this.httpSrv.post(this.serviceKey, url, body, JSON.stringify(body), {headers})
          }
        }),
        map((res)=>{
          return {res, file};
        }),
        tap(console.log),
        catchError((error)=>{
          if(error.ErrorCode === 700004)
          {
            return of(null);
          }
          else {
            return this.handleError(error);
          }
        })
      );
  }

  public uploadFileToSessionFileBlock(fileBlock: IBluebeamSessionCreateFileBlockRes | null, file: Blob): Observable<void> {
    if(fileBlock)
    {
      const headers = new HttpHeaders().set('Content-Type', fileBlock.UploadContentType).set('x-amz-server-side-encryption', 'AES256');
      // return this.http.put(fileBlock.UploadUrl, file, {headers})
      return this.httpSrv.put(this.serviceKey, fileBlock.UploadUrl, file, file.size.toString(), {headers})
        .pipe(
          tap(console.log),
          catchError(this.handleError)
        );
    }
  }

  public confirmUploadFileToSession(sessionId: string, fileId: string): Observable<unknown> {
    
    const url = `${this.sessionUrl}/${sessionId}/files/${fileId}/confirm-upload`;
    return this.getValidAccessToken()
      .pipe(
        switchMap((token)=>{
          if(token)
          {
            const headers = new HttpHeaders().set('Authorization', token).set('client_id', this.value_client_id);
            return this.httpSrv.post(this.serviceKey, url, null, null, {headers})
          }
        }),
        tap(console.log),
        catchError(this.handleError)
      );
  }

  public inviteUserToSession(sessionId: string, email: string, message: string): Observable<unknown> {
    
    const url = `${this.sessionUrl}/${sessionId}/invite`;
    const body = { 
      Email: email,
      Message: message
    };
    return this.getValidAccessToken()
      .pipe(
        switchMap((token)=>{
          if(token)
          {
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token).set('client_id', this.value_client_id);
            return this.httpSrv.post(this.serviceKey, url, body, JSON.stringify(body), {headers})
          }
        }),
        tap(console.log),
        catchError(this.handleError)
      );
  }

  public addUserToSession(sessionId: string, email: string, message: string, sendEmail = true): Observable<unknown> {
    const url = `${this.sessionUrl}/${sessionId}/users`;
    const body = { 
      Email: email,
      SendEmail: sendEmail,
      Message: message
    };
    return this.getValidAccessToken()
      .pipe(
        switchMap((token)=>{
          if(token)
          {
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token).set('client_id', this.value_client_id);
            return this.httpSrv.post(this.serviceKey, url, body, JSON.stringify(body), {headers})
          }
        }),
        tap(console.log),
        catchError(this.handleError)
      );
  }

  public getUsersOfSession(sessionId: string): Observable<IBluebeamGetUsersRes> {
    const url = `${this.sessionUrl}/${sessionId}/users`;
    return this.getValidAccessToken()
      .pipe(
        switchMap((token)=>{
          if(token)
          {
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token).set('client_id', this.value_client_id);
            return this.httpSrv.get(this.serviceKey, url, {headers})
          }
        }),
        tap(console.log),
        catchError(this.handleError)
      );
  }

  public updateUserPermissionOfSession(sessionId: string, userId: number): Observable<unknown> {
    const url = `${this.sessionUrl}/${sessionId}/users/${userId}/permissions`;
    const body = { 
      Allow: "Allow",
      Type: "FullControl"
    };
    return this.getValidAccessToken()
      .pipe(
        switchMap((token)=>{
          if(token)
          {
            const headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token).set('client_id', this.value_client_id);
            return this.httpSrv.put(this.serviceKey, url, body, JSON.stringify(body), {headers})
          }
        }),
        tap(console.log),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse)
  {
    if(error.status === 0)
    {
      console.log('An error occurred: ',error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(error);
  }
}
