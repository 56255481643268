import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import AccessDeniedComponent from "@content/auth/access-denied/access-denied.component";
import BaseComponent from "@content/base/base.component";
import ErrorComponent from "@content/error/error.component";
import { SignoutComponent } from "@content/signout/signout.component";
import BluebeamComponent from "@content/submittal/bluebeam/bluebeam.component";
import CompileComponent from "@content/submittal/compile/compile.component";
import CreateComponent from "@content/submittal/create/create.component";
import CreateOldComponent from "@content/submittal/createOld/createOld.component";
import DDCDispositionComponent from "@content/submittal/ddcDisposition/ddcDisposition.component";
import DispositionComponent from "@content/submittal/disposition/disposition.component";
import InreviewComponent from "@content/submittal/inreview/inreview.component";
import ListComponent from "@content/submittal/list/list.component";
import ResponseComponent from "@content/submittal/response/response.component";
import ReviewSubmittalComponent from "@content/submittal/review-submittal/review-submittal.component";
import SubmittedComponent from "@content/submittal/submitted/submitted.component";
import ViewComponent from "@content/submittal/view/view.component";
import { PendingChangesGuard } from "@guards/pending-changes.guard";
import CallBackResolver from "@resolvers/callback.resolver";
import ErrorResolve from "@resolvers/error-resolver";
import ListResolver from "@resolvers/list.resolver";
import LoginUserResolve from "@resolvers/login-user-resolver";
import RoutingResolver from "@resolvers/routing.resolver";
import TrackingLogResolver from "@resolvers/trackingLog.resolver";
import ViewResolver from "@resolvers/view.resolver";
import ViewAndNavResolver from "@resolvers/viewAndNav.resolver";
import RouteParams from "@shared/route-params";
import environment from "src/environments/environment";
import { BrowserUtils } from "@azure/msal-browser";
import AcknowledgementQaqcComponent from "@content/submittal/acknowledgementQaqc/acknowledgementQaqc.component";
import AcknowledgementComponent from "@content/submittal/acknowledgement/acknowledgement.component";
import CreateResolver from "@resolvers/create.resolver";
import BBCallbackComponent from "@content/bb_callback/bb_callback.component";

const routes: Routes = [
  {
    path: "unauthorized",
    component: AccessDeniedComponent,
    pathMatch: "full",
  },
  {
    path: `error/:${RouteParams.ErrorCode}`,
    component: ErrorComponent,
    canActivate: [MsalGuard],
    resolve: { loginuser: ErrorResolve },
  },
  {
    path: "sign-out",
    component: SignoutComponent,
    pathMatch: "full",
  },
  {
    path: "logout",
    component: SignoutComponent,
    pathMatch: "full",
  },
  {
    path: "bb_callback",
    component: BBCallbackComponent,
    resolve: { callback: CallBackResolver },
  },
  {
    path: `:${RouteParams.ContractId}`,
    component: BaseComponent,
    canActivate: [MsalGuard],
    runGuardsAndResolvers: "always",
    resolve: { loginuser: LoginUserResolve },
    children: [
      {
        path: "list",
        component: ListComponent,
        runGuardsAndResolvers: "always",
        canActivate: [MsalGuard],
        resolve: { submittalList: ListResolver },
      },
      {
        path: `:${RouteParams.ActionId}`,
        resolve: { log: TrackingLogResolver },
        children: [
          {
            path: `review/:${RouteParams.ItemId}`,
            component: ReviewSubmittalComponent,
            runGuardsAndResolvers: "always",
            canActivate: [MsalGuard],
            canDeactivate: [PendingChangesGuard],
            resolve: { routingResolver: RoutingResolver,submittalData: ViewResolver },
          },
          {
            path: `submitted/:${RouteParams.ItemId}`,
            component: SubmittedComponent,
            runGuardsAndResolvers: "always",
            canActivate: [MsalGuard],
            canDeactivate: [PendingChangesGuard],
            resolve: { routingResolver: RoutingResolver,submittalData: ViewResolver },
          },
          {
            path: `acknowledgeQaqc/:${RouteParams.ItemId}`,
            component: AcknowledgementQaqcComponent,
            runGuardsAndResolvers: "always",
            canActivate: [MsalGuard],
            canDeactivate: [PendingChangesGuard],
            resolve: { routingResolver: RoutingResolver,submittalData: ViewResolver },
          },
          {
            path: `acknowledgement/:${RouteParams.ItemId}`,
            component: AcknowledgementComponent,
            runGuardsAndResolvers: "always",
            canActivate: [MsalGuard],
            canDeactivate: [PendingChangesGuard],
            resolve: { routingResolver: RoutingResolver,submittalData: ViewResolver },
          },
          {
            path: `createNew/:${RouteParams.ItemId}`,
            component: CreateComponent,
            runGuardsAndResolvers: "always",
            canDeactivate: [PendingChangesGuard],
            canActivate: [MsalGuard],
            resolve: { viewData: ViewAndNavResolver, submittalData: CreateResolver },
          },
          {
            path: `create/:${RouteParams.ItemId}`,
            component: CreateOldComponent,
            runGuardsAndResolvers: "always",
            canDeactivate: [PendingChangesGuard],
            canActivate: [MsalGuard],
            resolve: { viewData: ViewAndNavResolver, submittalData: CreateResolver },
          },
          {
            path: `compile/:${RouteParams.ItemId}`,
            component: CompileComponent,
            runGuardsAndResolvers: "always",
            canDeactivate: [PendingChangesGuard],
            canActivate: [MsalGuard],
            resolve: {
              routingResolver: RoutingResolver,
              submittalData: ViewResolver,
            },
          },
          {
            path: `response/:${RouteParams.ItemId}`,
            component: ResponseComponent,
            runGuardsAndResolvers: "always",
            canDeactivate: [PendingChangesGuard],
            canActivate: [MsalGuard],
            resolve: {
              routingResolver: RoutingResolver,
              submittalData: ViewResolver,
            },
          },
          {
            path: `disposition/:${RouteParams.ItemId}`,
            component: DispositionComponent,
            runGuardsAndResolvers: "always",
            canDeactivate: [PendingChangesGuard],
            canActivate: [MsalGuard],
            resolve: {
              routingResolver: RoutingResolver,
              submittalData: ViewResolver,
            },
          },
          {
            path: `ddcDisposition/:${RouteParams.ItemId}`,
            component: DDCDispositionComponent,
            runGuardsAndResolvers: "always",
            canDeactivate: [PendingChangesGuard],
            canActivate: [MsalGuard],
            resolve: {
              routingResolver: RoutingResolver,
              submittalData: ViewResolver,
            },
          },
          {
            path: `inreview/:${RouteParams.ItemId}`,
            component: InreviewComponent,
            runGuardsAndResolvers: "always",
            canDeactivate: [PendingChangesGuard],
            canActivate: [MsalGuard],
            resolve: {
              routingResolver: RoutingResolver,
              submittalData: ViewResolver,
            },
          },
          {
            path: `view/:${RouteParams.ItemId}`,
            component: ViewComponent,
            runGuardsAndResolvers: "always",
            canActivate: [MsalGuard],
            resolve: {
              routingResolver: RoutingResolver,
              submittalData: ViewResolver,
            },
          },
          {
            path: `bluebeam/:${RouteParams.ItemId}`,
            component: BluebeamComponent,
            runGuardsAndResolvers: "always",
            canActivate: [MsalGuard],
            canDeactivate: [PendingChangesGuard],
            resolve: {
              routingResolver: RoutingResolver,
              submittalData: ViewResolver,
            },
          },
        ]
      },
      
      {
        path: `error/:${RouteParams.ErrorCode}`,
        component: ErrorComponent,
        canActivate: [MsalGuard],
      },
      {
        path: "",
        redirectTo: "list",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "**",
    redirectTo:
      environment.url.logoutURL.includes("localhost") ||
      environment.url.logoutURL.includes("http://127.0.0.1")
        // ? "037c7f4d-0562-46b1-ac09-d4c35c0d9705/list"
        ? "1ead764b-793c-4751-ba19-bd23622594df/list"
        : environment.url.portal,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
    }),
  ],
  exports: [RouterModule],
})
export default class AppRoutingModule {}
