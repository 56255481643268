import { AecomCoreModule, LayoutService } from "@aecom/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { AuthInterceptor, AuthService, BBJCoreModule } from "@bbj/components";
import ReviewSubmittalComponent from "@content/submittal/review-submittal/review-submittal.component";
import SubmittedComponent from "@content/submittal/submitted/submitted.component";
import LocalContractUserService from "@services/local-contractUser.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { ApiModule } from "src/app/api-generated/api.module";
import environment from "src/environments/environment";
import { OAuthSettings } from "../oauth";
import { BASE_PATH } from "./api-generated";
import AppRoutingModule from "./app-routing.module";
import AppComponent from "./app.component";
import AccessDeniedComponent from "./content/auth/access-denied/access-denied.component";
import BaseComponent from "./content/base/base.component";
import ErrorComponent from "./content/error/error.component";
import SelectUserComponent from "./content/selectUser/selectUser.component";
import CompileComponent from "./content/submittal/compile/compile.component";
import CreateComponent from "./content/submittal/create/create.component";
import DispositionComponent from "./content/submittal/disposition/disposition.component";
import ListComponent from "./content/submittal/list/list.component";
import ResponseComponent from "./content/submittal/response/response.component";
import SubDetailComponent from "./content/submittal/sub-detail/sub-detail.component";
import SubmittalResponseDetailComponent from "./content/submittal/submittalResponse-detail/submittalResponse-detail.component";
import ViewComponent from "./content/submittal/view/view.component";
import { PendingChangesGuard } from "./guards/pending-changes.guard";
import ErrorResolve from "./resolvers/error-resolver";
import LoginUserResolve from "./resolvers/login-user-resolver";
import RoutingResolver from "./resolvers/routing.resolver";
import LocalContractService from "./services/local-contract.service";
import LocalStorageService from "./services/local-storage.service";
import NavService from "./services/nav.service";
import ScheduleUploadService from "./services/schedule-upload.service";
import BluebeamSessionComponent from "./shared/bluebeamSession/bluebeamSession.component";
import DispositionListComponent from "./shared/dispositionList/dispositionList.component";
import FileListComponent from "./shared/fileList/fileList.component";
import { StorageKey } from "./shared/localstorage";
import NotifyListComponent from "./shared/notifyList/notifyList.component";
import ReviewListComponent from "./shared/reviewList/reviewList.component";
import SubBluebeamTabComponent from "./shared/subBluebeamTab/subBluebeamTab.component";
import SubCompiledTabComponent from "./shared/subCompiledTab/subCompiledTab.component";
import SubHeaderComponent from "./shared/subHeader/subHeader.component";
import ViewAndNavResolver from "@resolvers/viewAndNav.resolver";
import AzureBlobService from "@services/azureBlob.service";
import SubLogTabComponent from "@shared/subLogTab/subLogTab.component";
import LocalSubmittalItemService from "@services/local-submittalItem.service";
import LocalTaskService from "@services/local-task.service";
import { ScrollingModule } from "@angular/cdk/scrolling";
import CreateOldComponent from "@content/submittal/createOld/createOld.component";
import DDCDispositionComponent from "@content/submittal/ddcDisposition/ddcDisposition.component";
import TrackingLogResolver from "@resolvers/trackingLog.resolver";
import SubProcessingTabComponent from "@shared/subProcessingTab/subProcessingTab.component";
import BaseFunctionService from "@services/baseFunction.service";
import LeftPanelComponent from "@shared/leftPanel/leftPanel.component";
import { NgToggleModule } from "ng-toggle-button";
import ReopenDetailComponent from "@shared/reopen-detail/reopen-detail.component";
import ReopenModalComponent from "@shared/reopen-modal/reopen-modal.component";
import RequestCommentsModalComponent from "@shared/requestComments-modal/requestComments-modal.component";
import InreviewComponent from "@content/submittal/inreview/inreview.component";
import BluebeamComponent from "@content/submittal/bluebeam/bluebeam.component";
import RecallModalComponent from "@shared/recall-modal/recall-modal.component";
import { SignoutComponent } from "@content/signout/signout.component";
import ReadRecallModalComponent from "@shared/readRecall-modal/readRecall-modal.component";
import ReportComponent from "@content/submittal/report/report.component";
import FailureFormComponent from "@shared/failureForm/failureForm.component";
import RejectHistoryTableComponent from "@shared/rejectHistoryTable/rejectHistoryTable.component";
import RecallHistoryTableComponent from "@shared/recallHistoryTable/recallHistoryTable.component";
import RejectFormComponent from "@shared/rejectForm/rejectForm.component";
import SendBackModalComponent from "@shared/sendBack-modal/sendBack-modal.component";
import WorkflowHistoryComponent from "@shared/workflowHistory/workflowHistory.component";
import DelegateModalComponent from "@shared/delegate-modal/delegate-modal.component";
import ReopenHistoryTableComponent from "@shared/reopenHistoryTable/reopenHistoryTable.component";
import PreviousRouteService from "@services/previousRoute.service";
import ViewResolver from "@resolvers/view.resolver";
import BaseService from "@services/base.service";
import CreateResolver from "@resolvers/create.resolver";
import ListResolver from "@resolvers/list.resolver";
import DispositionReportService from "@services/disposition-report.service";
import FileManagementService from "@services/fileManagement.service";
import GetStatusService from "@services/get-status.service";
import LoadingService from "@services/loading.service";
import LocalApplicationRoleService from "@services/local-application_role.service";
import LocalContractRequirementService from "@services/local-contractRequirement.service";
import LocalKeyWordsService from "@services/local-keywords.service";
import LocalPhaseService from "@services/local-phase.service";
import LocalSpecificationService from "@services/local-specification.service";
import LocalSubmittalDocumentTypeService from "@services/local-submittalDocumentType.service";
import LocalSubmittalPageService from "@services/local-submittalPage.service";
import LocalSubmittalTypeService from "@services/local-submittalType.service";
import LocalUserService from "@services/local-user.service";
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { UpdateDueDateModalComponent } from "@shared/updateDueDate-modal/updateDueDate-modal.component";
import AcknowledgementQaqcComponent from "@content/submittal/acknowledgementQaqc/acknowledgementQaqc.component";
import AcknowledgementComponent from "@content/submittal/acknowledgement/acknowledgement.component";
import CallBackResolver from "@resolvers/callback.resolver";
import BBCallbackComponent from "@content/bb_callback/bb_callback.component";

export function tokenGetter(): string {
  return localStorage.getItem(StorageKey.TOKEN);
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: OAuthSettings.appId,
      authority: OAuthSettings.authority,
      redirectUri: environment.url.redirectUri,
      postLogoutRedirectUri: environment.url.logoutURL,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: OAuthSettings.protectedResourceMap,
    // unprotectedResources: OAuthSettings.unprotectedResources,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: OAuthSettings.scopes,
    },
    loginFailedRoute: environment.url.redirectUri,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    BaseComponent,
    ErrorComponent,
    SelectUserComponent,
    SignoutComponent,
    BluebeamSessionComponent,
    DelegateModalComponent,
    DispositionListComponent,
    FailureFormComponent,
    FileListComponent,
    LeftPanelComponent,
    NotifyListComponent,
    ReadRecallModalComponent,
    RecallModalComponent,
    RecallHistoryTableComponent,
    RejectFormComponent,
    RejectHistoryTableComponent,
    ReopenDetailComponent,
    ReopenModalComponent,
    ReopenHistoryTableComponent,
    RequestCommentsModalComponent,
    ReviewListComponent,
    SendBackModalComponent,
    SubBluebeamTabComponent,
    SubCompiledTabComponent,
    SubHeaderComponent,
    SubLogTabComponent,
    SubProcessingTabComponent,
    WorkflowHistoryComponent,
    AcknowledgementComponent,
    AcknowledgementQaqcComponent,
    BluebeamComponent,
    CompileComponent,
    CreateComponent,
    CreateOldComponent,
    DispositionComponent,
    DDCDispositionComponent,
    InreviewComponent,
    ListComponent,
    ReportComponent,
    ResponseComponent,
    ReviewSubmittalComponent,
    SubDetailComponent,
    SubmittalResponseDetailComponent,
    ViewComponent,
    SubmittedComponent,
    UpdateDueDateModalComponent,
    BBCallbackComponent
  ],
  imports: [
    AecomCoreModule,
    BBJCoreModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    HttpClientModule,
    NgxSpinnerModule,
    MsalModule,
    BrowserAnimationsModule,
    ApiModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    NgToggleModule.forRoot() //or NgToggleModule
  ],
  providers: [
    AuthService,
    PendingChangesGuard,
    CreateResolver,
    ErrorResolve,
    ListResolver,
    LoginUserResolve,
    RoutingResolver,
    TrackingLogResolver,
    ViewAndNavResolver,
    ViewResolver,
    AzureBlobService,
    BaseService,
    BaseFunctionService,
    DispositionReportService,
    FileManagementService,
    GetStatusService,
    LoadingService,
    LocalApplicationRoleService,
    LocalContractService,
    LocalContractRequirementService,
    LocalContractUserService,
    LocalKeyWordsService,
    LocalPhaseService,
    LocalSpecificationService,
    LocalStorageService,
    LocalSubmittalDocumentTypeService,
    LocalSubmittalItemService,
    LocalSubmittalPageService,
    LocalSubmittalTypeService,
    LocalTaskService,
    LocalUserService,
    NavService,
    ScheduleUploadService,
    PreviousRouteService,
    LayoutService,
    CallBackResolver,
    ErrorResolve,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: BASE_PATH, useValue: environment.baseWebAPI },
    MsalBroadcastService,
    MsalService,
    MsalGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
