import { v4 as uuidv4 } from 'uuid';
import { IPSubItem, ISubDispositionStep } from "../api-generated";
import SubmittalCreateBase from "./submittalCreateBase";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalDispositionBase from "./submittalDispositionBase";
import SubmittalStatus from "./submittalStatus";
import { reviewSubmittalTypeId } from "@shared/staticValue";
import { getLatestCreateItem } from '@shared/utils';

export default class SubmittalDisposition
  extends SubmittalItemUpdateBase
  implements ISubDispositionStep
{
  public submittal_create: SubmittalCreateBase;

  public submittal_disposition: SubmittalDispositionBase;

  public IsBluebeamRequired?: boolean;

  public tempId?: string;

  public DDCManagerId?: string;

  public SrManagerId?: string;

  constructor(contractId: string, showSRReview: boolean, item?: IPSubItem) {
    super(contractId, item);

    this.IsBluebeamRequired = item?.IsBluebeamRequired;

    this.DDCManagerId = item?.DDCManagerId;

    this.SrManagerId = item?.SrManagerId;

    const isSrReview = item?.Status === SubmittalStatus.SPM_Review;
    const isDDCReview = item?.Status === SubmittalStatus.DDC_Disposition;

    const createItem = getLatestCreateItem(item);

    this.submittal_create = new SubmittalCreateBase(createItem);


    const defaultSrYes = (createItem ? createItem.SubmittalTypeId !== reviewSubmittalTypeId : false) && showSRReview;
    const compiles = (item?.submittal_compile ?? []).concat(item?.submittal_disposition??[]);
    if(compiles && compiles.length>0)
    {
      const lastCompile = compiles.sort(
        (a, b) =>
          new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime(),
      )[0];
      this.submittal_disposition = new SubmittalDispositionBase(
        defaultSrYes,
        isSrReview,
        isDDCReview,
        lastCompile,
      );
      if(lastCompile.IsDraft!==true)
      {
        this.tempId = uuidv4();
      }
    } else {
      this.submittal_disposition = new SubmittalDispositionBase(
        defaultSrYes,
        isSrReview,
        isDDCReview
      );

      this.tempId = uuidv4();
    }
  }
}
