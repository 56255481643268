import SubmittalRole from "@models/submittalRole";
import SubmittalStatus from "@models/submittalStatus";
import LocalUserService from "@services/local-user.service";
import {
  getBtnUrlP,
  getCanEditP,
  getCompilingValue,
  getDateFormatted_MMDDYYYY,
  getDateWithOffset,
  getLatestCreateItem,
  getSelectBICforCurrentStatus,
  getStatusText,
  isReviewer,
  shouldReviewP,
} from "@shared/utils";
import {
  IPSubListItem,
  IUser,
  IPRFISpecification,
  SubmittalDocumentType,
  SubmittalType,
  IPRFIContractRequirement,
  Phase,
} from "../api-generated";
import LocalApplicationRoleService from "@services/local-application_role.service";
import { queensGarage } from "@shared/staticValue";

//Submittal view model
export default class SubmittalListRowItem {
  public id: string;
  public priority: number;
  public submittalNumber: string;
  public submittalTitle: string;
  public specificationTitle: string;
  public specificationId: string;
  public doctypetitle: string;
  public doctypeCode: string;
  public docTypeId: string;
  public submittalTypeTitle: string;
  public submittalTypeId: string;
  public phaseTitle: string;
  public phaseId: string;
  public issueDate: string;
  public internalDueDate: string;
  public expectedResponseDate: string;
  public responseDate: string;
  public issueDateLog: string | Date;
  public internalDueDateLog: string | Date;
  public expectedResponseDateLog: string | Date;
  public responseDateLog: string | Date;
  public issueDateSort: string;
  public internalDueDateSort: string;
  public expectedResponseDateSort: string;
  public responseDateSort: string;
  // guid id
  public ballInCourt: string;
  //not returned
  public ballInCourtUser: IUser;
  public ballInCourtRole: string;
  public status: string;
  public isHidden: boolean;
  public viewOnly: boolean;
  public btnText: string;
  public btnClass: string;
  public btnUrl: string;
  public statusText?: string;
  public substatusText?: string;
  public statusicon?: string;
  public altUrl?: string;
  public compilingValue?: number;
  public isConfidential?: boolean;
  public showRecallRejected?: boolean;
  public initSort: number;
  public contractRequirementTitle: string;
  public contractRequirementId: string;

  public DateSubmitted: string;

  public amended: boolean;

  constructor(
    localApplicationRoleService: LocalApplicationRoleService,
    contractId: string,
    currentuserId: string,
    subItem: IPSubListItem,
    userRole: string,
    isOld: boolean,
    docType?: SubmittalDocumentType,
    spec?: IPRFISpecification,
    subtype?: SubmittalType,
    contractRequirement?: IPRFIContractRequirement,
    phase?: Phase,
    public localUserService?: LocalUserService,
  ) {
    this.id = subItem.Guid;
    const createItem = getLatestCreateItem(subItem);
    if (createItem) {
      this.priority = createItem.Priority ?? 1;
      this.submittalTitle = createItem.Title;
      this.isConfidential = createItem.IsConfidentail ?? false;
    } else {
      this.priority = 1;
      this.submittalTitle = "";
      this.expectedResponseDate = "-";
      this.expectedResponseDateSort = "-";
      this.isConfidential = false;
    }
    this.submittalNumber = subItem.SubmittalNumber;
    this.specificationTitle = spec ? `${spec.SpecId} - ${spec.Title}` : "";
    this.specificationId = spec?.Guid;
    this.doctypetitle = docType?.Title;
    this.doctypeCode = docType?.Code;
    this.docTypeId = docType?.Guid;
    this.submittalTypeId = subtype?.Guid;
    this.submittalTypeTitle = subtype?.Title;
    this.phaseId = phase?.Guid;
    this.phaseTitle = phase?.Title;
    this.contractRequirementId = contractRequirement?.Guid;
    this.contractRequirementTitle = contractRequirement?.Name;
    this.DateSubmitted =subItem.DateSubmitted
    this.issueDate = subItem.DateSubmitted
      ? this.getDate(subItem.DateSubmitted, isOld)
      : "-";
    this.internalDueDate = subItem.InternalDueDate
      ? this.getDate(subItem.InternalDueDate, isOld)
      : "-";
    this.responseDate = subItem.DateReviewed
      ? this.getDate(subItem.DateReviewed, isOld)
      : "-";
    this.expectedResponseDate = subItem.DueDate
      ? this.getDate(subItem.DueDate, isOld)
      : "-";
    this.issueDateLog = subItem.DateSubmitted
      ? getDateWithOffset(subItem.DateSubmitted)
      : "-";
    this.internalDueDateLog = subItem.InternalDueDate
      ? getDateWithOffset(subItem.InternalDueDate)
      : "-";
    this.responseDateLog = subItem.DateReviewed
      ? getDateWithOffset(subItem.DateReviewed)
      : "-";
    this.expectedResponseDateLog = subItem.DueDate
      ? getDateWithOffset(subItem.DueDate)
      : "-";
    this.issueDateSort = subItem.DateSubmitted ?? "-";
    this.internalDueDateSort = subItem.InternalDueDate ?? "-";
    this.responseDateSort = subItem.DateReviewed ?? "-";
    this.expectedResponseDateSort = subItem.DueDate ?? "-";
    const selectedBIC = getSelectBICforCurrentStatus(subItem);
    this.ballInCourt = subItem.BallInCourt;
    this.ballInCourtUser = this.localUserService.getItems().find((u)=>u.id===(this.ballInCourt === SubmittalRole.Selected ? selectedBIC.BIC : subItem.BallInCourtId));
    this.ballInCourtRole = localApplicationRoleService.getRoleById(this.ballInCourt);

    this.status = subItem.IsRecalled
      ? SubmittalStatus.Recalled
      : subItem.Status;
    this.isHidden = false;
    this.showRecallRejected = subItem.ShowRecallRejected ?? false;

    this.compilingValue = getCompilingValue(subItem);
    const needReview = shouldReviewP(subItem, currentuserId);
    const hasReviewerRole = isReviewer(subItem, currentuserId);
    const reviewResponseStep = subItem.Status === SubmittalStatus.Compiling || subItem.Status === SubmittalStatus.Compiling_Disposition;

    this.viewOnly = !getCanEditP(subItem, userRole, currentuserId, hasReviewerRole);

    const isRepresent = currentuserId !== subItem.CoordinatorId && (
      (userRole !== SubmittalRole.Manager && hasReviewerRole && !needReview && reviewResponseStep) ||
      (userRole === SubmittalRole.Manager && hasReviewerRole && reviewResponseStep)
    );
    const newReview = hasReviewerRole && needReview && reviewResponseStep;
    this.initSort = this.getInitSort(this.viewOnly, isRepresent);
    this.btnText = this.getBtnText(subItem, userRole, this.viewOnly);
    this.btnClass = this.getBtnClass(this.viewOnly, isRepresent, newReview);

    this.btnUrl = getBtnUrlP(
      1,
      contractId,
      subItem,
      this.viewOnly,
      userRole,
      currentuserId,
      hasReviewerRole,
    );
    this.amended = this.isAmended(subItem);
    if (
      userRole === SubmittalRole.Doc_Control && subItem.Status === SubmittalStatus.Submitted
    ) {
      if(subItem.ContractId==queensGarage)
      {
        this.altUrl = `${contractId}/1/create/${subItem.Guid}`;
      } else {
        this.altUrl = `${contractId}/1/createNew/${subItem.Guid}`;
      }
    }

    if (
      (userRole === SubmittalRole.Doc_Control || currentuserId === subItem.CoordinatorId ) &&
      (subItem.Status === SubmittalStatus.Bluebeam_Compiling ||
        subItem.Status === SubmittalStatus.Bluebeam_Disposition ||
        subItem.Status === SubmittalStatus.Bluebeam_Uploaded) &&
      subItem.IsRecalled !== true
    ) {
      this.altUrl = `${contractId}/1/bluebeam/${subItem.Guid}`;
    }

    if (
      currentuserId === subItem.CoordinatorId &&
      subItem.Status === SubmittalStatus.Compiling &&
      subItem.IsRecalled !== true && hasReviewerRole
    ) {
      this.altUrl = `/${contractId}/1/response/${subItem.Guid}`;
    }
    const isReopen = subItem.submittal_reopen?.length > 0;
    const isDB = userRole === SubmittalRole.DBAdmin;
    this.statusText = getStatusText(
      this.status,
      isDB,
      isReopen
    );
    this.substatusText = isDB
      ? null
      : this.getsubStatusText(this.status);
    this.statusicon = this.getIcon(
      this.status,
      isDB,
    );
  }

  getBtnText(sub: IPSubListItem, role: string, viewOnly: boolean): string {
    const view = "View";
    const review = "Review";
    const upload = "Upload";
    const edit = "Edit";
    const unknow = "Unknow";
    if (sub.IsRecalled) {
      return viewOnly ? view : review;
    } else {
      switch (sub.Status) {
        case SubmittalStatus.Draft:
          return viewOnly ? view : edit;
        case SubmittalStatus.Submitted:
        case SubmittalStatus.Request_to_Reopen:
        case SubmittalStatus.QAQC_Fail:
        case SubmittalStatus.In_Review:
        case SubmittalStatus.Disposition:
        case SubmittalStatus.SPM_Review:
        case SubmittalStatus.DDC_Disposition:
        case SubmittalStatus.Bluebeam:
        case SubmittalStatus.Bluebeam_Compiling:
        case SubmittalStatus.Compiling:
        case SubmittalStatus.Bluebeam_Uploaded:
        case SubmittalStatus.Bluebeam_Disposition:
        case SubmittalStatus.Compiling_Disposition:
        case SubmittalStatus.Acknowledgement:
          return viewOnly ? view : review;
        case SubmittalStatus.Bluebeam_Request:
          return viewOnly
            ? view
            : role === SubmittalRole.Doc_Control
            ? upload
            : review;
        case SubmittalStatus.Revise_Resubmit:
        case SubmittalStatus.Accepted_With_Comments:
        case SubmittalStatus.Reviewed:
        case SubmittalStatus.Reviewed_With_Comments:
        case SubmittalStatus.Accepted:
          return viewOnly
            ? view
            : sub.BallInCourt === SubmittalRole.Doc_Control &&
              role === SubmittalRole.Doc_Control
            ? upload
            : review;
        case SubmittalStatus.Resubmitted:
        case SubmittalStatus.In_Revision:
        case SubmittalStatus.Canceled:
        case SubmittalStatus.Voided:
        case SubmittalStatus.Denied:
        case SubmittalStatus.Acknowledged:
          return view;
        default:
          return unknow;
      }
    }
  }

  getInitSort(viewonly: boolean, isRepresent: boolean): number {
    return viewonly
      ? 0
      : isRepresent
      ? 1
      : 2;
  }

  getBtnClass(viewonly: boolean, isRepresent: boolean, isNewReview: boolean) {
    if(viewonly)
    {
      return "btn outline lg blue";
    }
    if(isNewReview)
    {
      return "btn solid lg newReviewBottonColor";
    }
    if(isRepresent)
    {
      return "btn solid lg altBottonColor";
    }
    return "btn solid lg blue";
  }

  getsubStatusText(status: string) {
    switch (status) {
      case SubmittalStatus.Bluebeam_Compiling:
        return "Compiling";
      case SubmittalStatus.Bluebeam_Request:
        return "Request";
      case SubmittalStatus.Bluebeam_Uploaded:
        return "Uploaded";
      case SubmittalStatus.Bluebeam_Disposition:
        return "Disposition";
      default:
        return null;
    }
  }

  getIcon(status, isDB = false) {
    switch (status) {
      case SubmittalStatus.Draft:
        return this.getIconStr("icon-action_write", false);
      case SubmittalStatus.Submitted:
      case SubmittalStatus.Reopen_in_Progress:
        return this.getIconStr("icon-action_send", false);
      case SubmittalStatus.QAQC_Fail:
      case SubmittalStatus.Denied:
        return this.getIconStr("icon-document_close", false);
      case SubmittalStatus.In_Review:
        return isDB
          ? this.getIconStr("icon-action_send", false)
          : this.getIconStr("icon-action_rotate_anticlockwise", false);
      case SubmittalStatus.Compiling:
      case SubmittalStatus.Compiling_Disposition:
        return isDB
          ? this.getIconStr("icon-action_send", false)
          : this.getIconStr("icon-action_combine", false);
      case SubmittalStatus.Bluebeam:
        return isDB
          ? this.getIconStr("icon-action_send", false)
          : this.getIconStr("icon-bluebeam", true);
      case SubmittalStatus.Bluebeam_Compiling:
        return isDB
          ? this.getIconStr("icon-action_send", false)
          : this.getIconStr("icon-bluebeam", true);
      case SubmittalStatus.Bluebeam_Request:
        return isDB
          ? this.getIconStr("icon-action_send", false)
          : this.getIconStr("icon-bluebeam", true);
      case SubmittalStatus.Bluebeam_Uploaded:
        return isDB
          ? this.getIconStr("icon-action_send", false)
          : this.getIconStr("icon-bluebeam", true);
      case SubmittalStatus.Bluebeam_Disposition:
        return isDB
          ? this.getIconStr("icon-action_send", false)
          : this.getIconStr("icon-bluebeam", true);
      case SubmittalStatus.Disposition:
      case SubmittalStatus.SPM_Review:
      case SubmittalStatus.DDC_Disposition:
        return isDB
          ? this.getIconStr("icon-action_send", false)
          : this.getIconStr("icon-document_accept", false);
      case SubmittalStatus.Acknowledgement:
        return isDB
          ? this.getIconStr("icon-action_send", false)
          : this.getIconStr("icon-action_exclamation", false);
      case SubmittalStatus.Acknowledged:
        return this.getIconStr("icon-action_exclamation", false);
      case SubmittalStatus.Revise_Resubmit:
        return this.getIconStr("icon-action_clockwise", false);
      case SubmittalStatus.Reviewed:
      case SubmittalStatus.Reviewed_With_Comments:
      case SubmittalStatus.Accepted:
      case SubmittalStatus.Accepted_With_Comments:
        return this.getIconStr("icon-action_special", false);
      case SubmittalStatus.Recalled:
      case SubmittalStatus.Canceled:
      case SubmittalStatus.Voided:
        return this.getIconStr("icon-action_remove_circle", false);
      case SubmittalStatus.Resubmitted:
      case SubmittalStatus.In_Revision:
        return this.getIconStr("icon-document_close", false);
      case SubmittalStatus.Request_to_Reopen:
        return this.getIconStr("icon-action_re-send", false);
      default:
        return null;
    }
  }

  getIconStr(iconname: string, isBluebeam: boolean): string {
    return isBluebeam
      ? "icon" + " " + iconname + " " + "icon-blubeam-blue icon-lg"
      : "icon" + " " + iconname + " " + "icon-purple icon-lg";
  }

  getDate(date: string, isold: boolean): string {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    } as Intl.DateTimeFormatOptions;

    const tempDate = new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));

    if(isold)
    {
      return tempDate
      .toLocaleDateString(undefined, options)
      .replace(", ", "-")
      .replace(" ", "-");
    }
    else {
      return getDateFormatted_MMDDYYYY(tempDate);
    }
    
  }
  private isAmended(item: IPSubListItem): boolean {
    const reopen = item.submittal_reopen?.sort((a,b)=>new Date(a.DateRequest).getTime()-new Date(b.DateRequest).getTime());
    if(reopen?.length > 0)
    {
      const closeStatusArr: string[] = [
        SubmittalStatus.Canceled,
        SubmittalStatus.Denied,
        SubmittalStatus.Accepted,
        SubmittalStatus.Accepted_With_Comments,
        SubmittalStatus.Reviewed,
        SubmittalStatus.Reviewed_With_Comments,
        SubmittalStatus.Revise_Resubmit
      ];
      const reopenTime = new Date(reopen[0].DateRequest).getTime();
      const flow = item.submittal_flow?.find((f)=>{return new Date(f.DateReceived).getTime() > reopenTime && closeStatusArr.includes(f.Status) });
      if(flow)
      {
        return true;
      }
    }
    return false;
  }
}
