import { v4 as uuidv4 } from 'uuid';
import { IPSubItem, ISubQaqcStep } from "../api-generated";
import SubmittalCreateBase from "./submittalCreateBase";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalRejectDetails from "./submittalRejectDetail";
import SubmittalNotesBase from "./submittalNotesBase";
import SubmittalBluebeam from './submittalBluebeam';
import { getLatestCreateItem } from '@shared/utils';

export default class SubmittalQaqc
  extends SubmittalItemUpdateBase
  implements ISubQaqcStep
{
  public submittal_create: SubmittalCreateBase;

  public tempId?: string;

  public ManagerId?: string;
  public CoordinatorId?: string;
  public IsQAQCCompliance?: boolean;
  public IsBluebeamRequired?: boolean;
  public submittal_bluebeam_detail?: SubmittalBluebeam;
  public submittal_reject_detail?: SubmittalRejectDetails;
  public submittal_reviewer: string[];

  public submittal_watcher: string[];
  public submittal_notes?: SubmittalNotesBase;

  constructor(contractId: string, item: IPSubItem) {
    super(contractId, item);
    this.submittal_create = new SubmittalCreateBase(getLatestCreateItem(item));

    this.ManagerId = item.ManagerId;
    this.CoordinatorId = item.CoordinatorId;
    this.IsBluebeamRequired = this.submittal_create.IsDraft === false ? false : item.IsBluebeamRequired === true;
    this.IsQAQCCompliance = this.submittal_create.IsDraft === false ? true : item.IsQAQCCompliance !== false;
    this.submittal_reviewer =
      item.submittal_reviewer?.map((i) => {
        return i.ReviewerId ?? "";
      }) ?? [];

    this.submittal_watcher =
      item.submittal_watcher?.map((i) => {
        return i.WatcherId ?? "";
      }) ?? [];
    this.submittal_bluebeam_detail = item.submittal_bluebeam_detail && item.submittal_bluebeam_detail.length > 0 ? new SubmittalBluebeam(item.submittal_bluebeam_detail[0]) : new SubmittalBluebeam();
    
    if (!this.submittal_reject_detail) {
      this.tempId = uuidv4();
      this.submittal_reject_detail = new SubmittalRejectDetails();
    }
    const notes = item.submittal_notes?.find((notes)=>{return notes.IsDraft === true && notes.Status === item.Status && notes.DeletedBy === null});
    if(notes)
    {
      this.submittal_notes = new SubmittalNotesBase(notes);
    }
    else 
    {
      this.submittal_notes = new SubmittalNotesBase();
    }
  }
}
