import { v4 as uuidv4 } from 'uuid';
import { IPSubItem, ISubBluebeamStep } from "../api-generated";
import SubmittalBluebeam from "./submittalBluebeam";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalRejectDetail from "./submittalRejectDetail";

export default class SubmittalBluebeamStep
  extends SubmittalItemUpdateBase
  implements ISubBluebeamStep
{
  public submittal_bluebeam_detail: SubmittalBluebeam;

  public submittal_reviewer: string[];

  public submittal_watcher: string[];

  public tempId?: string;

  public IsQAQCCompliance?: boolean;

  public submittal_reject_detail?: SubmittalRejectDetail;

  public autoSave?: boolean;

  constructor(contractId: string, item: IPSubItem) {
    super(contractId, item);
    
    this.submittal_reviewer =
      item.submittal_reviewer?.map((i) => {
        return i.ReviewerId ?? "";
      }) ?? [];

    this.submittal_watcher =
      item.submittal_watcher?.map((i) => {
        return i.WatcherId ?? "";
      }) ?? [];
    this.submittal_bluebeam_detail = item.submittal_bluebeam_detail && item.submittal_bluebeam_detail.length > 0 ? new SubmittalBluebeam(item.submittal_bluebeam_detail[0]) : new SubmittalBluebeam();

    this.IsQAQCCompliance = item?.IsQAQCCompliance;

    if (!this.submittal_reject_detail) {
      this.tempId = uuidv4();
      this.submittal_reject_detail = new SubmittalRejectDetail();
    }
  }
}
