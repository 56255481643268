import { FileModel, ModalContainerService, NotificationComponent, NotificationType } from "@aecom/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import FileModelWithVersion from "@models/fileModelWithVersion";
import SubmittalRole from "@models/submittalRole";
import SubmittalStatus from "@models/submittalStatus";
import {
  IPSubDocumentDownload,
  IPSubItem,
  IPSubListItem,
  IUser,
  SubmittalDelegateDetail,
  SubmittalPage,
  ISubDelegateDetail,
  SubmittalDuedate,
  SubmittalSelectBic,
  SubmittalAcknowledgement,
  IPSubCreateItem,
} from "../api-generated";
import {
  queensGarage,
  reviewSubmittalTypeId,
  ReviewTypeReadOnly,
  submittalAppId,
  versionConst,
} from "./staticValue";
import RowItem from "@content/selectUser/table-row";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import LocalSubmittalDocumentTypeService from "@services/local-submittalDocumentType.service";
import environment from "src/environments/environment";

export function getDateWithOffset(date: string): Date {
  return new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
}

export function isFileNameInvalid(name: string): boolean {
  const specialChars = /[`!#$%^&*+=\[\]{};':"\\|,<>\/?~]/;
  return specialChars.test(name);
}

export function getDateFormatted_MMDDYYYY(date: Date | string): string {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = (1 + d.getMonth()).toString().padStart(2, "0");
  const day = d.getDate().toString().padStart(2, "0");

  return `${month}/${day}/${year}`;
}

export function getFormattedDate(date: Date | string): string {
  const d = new Date(date);
  const datetimestr = d.toLocaleTimeString([], {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  return datetimestr;
}
//Jan 20 wed, 5:30 PM

export function getDate(date: string): string {
  return new Date(date).toLocaleDateString();
}
export function getReportDate(date: string): string {
  return new Date(date).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"}) 

}

export function getTime(date: string): string {
  return new Date(date).toLocaleTimeString();
}

export function getFormattedTime(date: string): string {
  return `${new Date(date).getHours().toString().padStart(2, "0")}:${new Date(
    date,
  )
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
}

export function getTimeString(date: string): string {
  const now = new Date(date);
  const hours = ("0" + now.getHours()).slice(-2);
  const minutes = ("0" + now.getMinutes()).slice(-2);
  const str = hours + ":" + minutes;
  return str;
}

export function getFilesByType(
  documents: IPSubDocumentDownload[],
  documentType?: string,
): FileModelWithVersion[] {
  return documents
    .filter((item) => {
      return documentType ? item.FileType === documentType : true;
    })
    .map((item) => {
      return new FileModelWithVersion(item);
    })
    .sort((a, b) => {
      return new Date(b.Base64).getTime() - new Date(a.Base64).getTime();
    });
}

export function getVersionString(submittalNumber: string): string {
  const array = submittalNumber.split("_");
  const version = array[array.length - 1];
  return version;
}

export function increaseVersion(versionNumber: string): string {
  const newVersion =
    (parseInt(versionNumber.replace(versionConst, ""), 10) ?? 0) + 1;
  const pad = "00";
  const versionString =
    pad.substring(0, pad.length - newVersion.toString().length) +
    newVersion.toString();
  return versionConst + versionString;
}

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function isValidBluebeamSessionId(sessionId: string): boolean {
  if (!sessionId || sessionId === "") {
    return false;
  }

  const pattern = /^[0-9]{3}-[0-9]{3}-[0-9]{3}$/;
  return !!sessionId.match(pattern);
}

export function getDisplayedTime(a): string {
  const time = getTime(a).split(":");
  time[2] = time[2].substr(2);

  return `${time[0]}:${time[1]} ${time[2]}`;
}

export function shouldReview(subItem: IPSubItem, userId: string): boolean {
  let needReview = false;
  if (subItem.submittal_reviewer && subItem.submittal_reviewer.length > 0) {
    const reviewer = subItem.submittal_reviewer.find((r) => {
      return r.ReviewerId === userId && !r.DeletedBy;
    });
    if (reviewer) {
      if (
        reviewer.submittal_reviewer_response &&
        reviewer.submittal_reviewer_response.length > 0
      ) {
        const responde = reviewer.submittal_reviewer_response.find((r) => {
          return (
            r.IsDraft !== true &&
            r.Submittal_version === getVersionString(subItem.SubmittalNumber)
          );
        });
        if (!responde) {
          needReview = true;
        }
      } else {
        needReview = true;
      }
    }
  }
  return needReview;
}

export function getCanEditP(
  sub: IPSubItem,
  role: string,
  userId: string,
  isReviewer: boolean,
): boolean {
  const dbId = sub.SubmittedBy ?? getLatestCreateItem(sub)?.CreatedBy ?? "";
  const isConfidential = getLatestCreateItem(sub)? getLatestCreateItem(sub).IsConfidentail : false;
  return canEditSubmittal(
    sub,
    role,
    userId,
    isReviewer,
    isConfidential,
    dbId,
    sub.ManagerId,
    sub.CoordinatorId,
    sub.SrManagerId,
    sub.DDCManagerId
  );
}

function canEditSubmittal(
  sub: IPSubItem | IPSubItem,
  role: string,
  userId: string,
  isReviewer: boolean,
  isConfidential: boolean,
  dbId?: string,
  managerId?: string,
  coordinatorId?: string,
  srManagerId?: string,
  ddcManagerId?: string,
): boolean {
  if (sub.IsRecalled) {
    return role === SubmittalRole.Doc_Control;
  } else {
    switch (sub.Status) {
      case SubmittalStatus.Draft:
        return isConfidential
          ? dbId === userId &&
              (role === SubmittalRole.DBAdmin ||
                role === SubmittalRole.Doc_Control)
          : role === SubmittalRole.DBAdmin ||
              role === SubmittalRole.Doc_Control;
      case SubmittalStatus.Submitted:
        return role === SubmittalRole.Doc_Control;
      case SubmittalStatus.Acknowledgement:
        const selectBic = getSelectBICforCurrentStatus(sub);
        return userId === selectBic?.BIC;
      case SubmittalStatus.QAQC_Fail:
        return (
          role === SubmittalRole.DBAdmin ||
          role === SubmittalRole.Doc_Control
        );
      case SubmittalStatus.Disposition:
        return managerId === userId;
      case SubmittalStatus.SPM_Review:
        return srManagerId === userId;
      case SubmittalStatus.DDC_Disposition:
        return ddcManagerId === userId;
      case SubmittalStatus.Bluebeam:
        return role === SubmittalRole.Doc_Control || coordinatorId === userId;
      case SubmittalStatus.In_Review:
        return coordinatorId === userId;
      case SubmittalStatus.Bluebeam_Compiling:
      case SubmittalStatus.Compiling:
        return coordinatorId === userId || isReviewer;
      case SubmittalStatus.Bluebeam_Request:
      case SubmittalStatus.Bluebeam_Uploaded:
        return coordinatorId === userId ||
          role === SubmittalRole.Doc_Control ||
          isReviewer;
      case SubmittalStatus.Revise_Resubmit:
        return (
          (sub.BallInCourt === SubmittalRole.DBAdmin &&
            role === SubmittalRole.DBAdmin) ||
          role === SubmittalRole.Doc_Control
        );
      case SubmittalStatus.Resubmitted:
      case SubmittalStatus.In_Revision:
      case SubmittalStatus.Canceled:
      case SubmittalStatus.Voided:
      case SubmittalStatus.Denied:
      case SubmittalStatus.Acknowledged:
        return false;
      case SubmittalStatus.Accepted_With_Comments:
      case SubmittalStatus.Reviewed:
      case SubmittalStatus.Reviewed_With_Comments:
      case SubmittalStatus.Accepted:
        return (
          (sub.BallInCourt === SubmittalRole.Doc_Control &&
            role === SubmittalRole.Doc_Control) ||
          (sub.BallInCourt === SubmittalRole.DBAdmin &&
            role === SubmittalRole.DBAdmin &&
            sub.Viewed !== true)
        );
      case SubmittalStatus.Bluebeam_Disposition:
      case SubmittalStatus.Compiling_Disposition:
        return role === SubmittalRole.Manager && managerId === userId || isReviewer;
      default:
        return true;
    }
  }
}

export function shouldReviewP(subItem: IPSubItem, userId: string): boolean {
  let needReview = false;
  if (subItem.submittal_reviewer && subItem.submittal_reviewer.length > 0) {
    const reviewer = subItem.submittal_reviewer.find((r) => {
      return r.ReviewerId === userId && !r.DeletedBy;
    });
    if (reviewer) {
      if (
        reviewer.submittal_reviewer_response &&
        reviewer.submittal_reviewer_response.length > 0
      ) {
        const responde = reviewer.submittal_reviewer_response.find((r) => {
          return (
            r.IsDraft !== true &&
            r.Submittal_version === getVersionString(subItem.SubmittalNumber)
          );
        });
        if (!responde) {
          needReview = true;
        }
      } else {
        needReview = true;
      }
    }
  }
  return needReview;
}

export function isReviewer(subItem: IPSubItem, userId: string): boolean {
  if (subItem.submittal_reviewer && subItem.submittal_reviewer.length > 0) {
    const reviewer = subItem.submittal_reviewer.find((r) => {
      return r.ReviewerId === userId && !r.DeletedBy;
    });
    if (reviewer) {
      return true;
    }
  }
  return false;
}

export function getCompilingValue(subItem: IPSubListItem): number {
  let compilingValue = -1;
  if (subItem.submittal_reviewer && subItem.submittal_reviewer.length > 0) {
    const reviewers = subItem.submittal_reviewer.filter((r) => {
      return !r.DeletedBy;
    });
    compilingValue = 0;
    if (reviewers.length > 0) {
      compilingValue = Math.round(
        (reviewers.filter((reviewer) => {
          if (
            reviewer.submittal_reviewer_response &&
            reviewer.submittal_reviewer_response.length > 0
          ) {
            const responde = reviewer.submittal_reviewer_response.find((r) => {
              return (
                r.IsDraft !== true &&
                r.Submittal_version ===
                  getVersionString(subItem.SubmittalNumber)
              );
            });
            return responde ? true : false;
          }
          return false;
        }).length /
          reviewers.length) *
          100,
      );
    }
  }
  return compilingValue;
}

export function getBtnUrlP(
  actionId: number,
  contractId: string,
  subItem: IPSubItem,
  viewOnly: boolean,
  role: string,
  userId: string,
  isReviewer: boolean,
): string {
  return getlink(
    actionId,
    contractId,
    subItem,
    viewOnly,
    role,
    userId,
    isReviewer,
    subItem.ManagerId,
    subItem.CoordinatorId,
    subItem.SrManagerId,
    subItem.DDCManagerId
  );
}

function getlink(
  actionId: number,
  contractId: string,
  subItem: IPSubItem | IPSubItem,
  viewOnly: boolean,
  role: string,
  userId: string,
  isReviewer: boolean,
  managerId?: string,
  coordinatorId?: string,
  srManagerId?: string,
  ddcManagerId?: string,
): string {
  if (viewOnly) {
    return `${contractId}/${actionId}/view/${subItem.Guid}`;
  }
  if (subItem.IsRecalled) {
    return `${contractId}/${actionId}/bluebeam/${subItem.Guid}`;
  } else {
    switch (subItem.Status) {
      case SubmittalStatus.Draft:
        if(subItem.ContractId==queensGarage)
        {
          return `${contractId}/${actionId}/create/${subItem.Guid}`;
        } else
        {
          return `${contractId}/${actionId}/createNew/${subItem.Guid}`;
        }
      case SubmittalStatus.Submitted:
        const isRecordOnly = getLatestCreateItem(subItem)?.SubmittalTypeId === ReviewTypeReadOnly;
        if(isRecordOnly)
        {
          return `${contractId}/${actionId}/acknowledgeQaqc/${subItem.Guid}`;
        }
        return `${contractId}/${actionId}/submitted/${subItem.Guid}`;
      case SubmittalStatus.Acknowledgement:
        return `${contractId}/${actionId}/acknowledgement/${subItem.Guid}`;
      case SubmittalStatus.In_Review:
        return `${contractId}/${actionId}/inreview/${subItem.Guid}`;
      case SubmittalStatus.Bluebeam:
        return `${contractId}/${actionId}/bluebeam/${subItem.Guid}`;
      case SubmittalStatus.Bluebeam_Request:
      case SubmittalStatus.Bluebeam_Uploaded:
      case SubmittalStatus.Bluebeam_Compiling:
      case SubmittalStatus.Bluebeam_Disposition:
        if(coordinatorId === userId)
        {
          return `${contractId}/${actionId}/compile/${subItem.Guid}`;
        }
        else if (
          isReviewer ||
          (role === SubmittalRole.Doc_Control &&
            subItem.BallInCourt === SubmittalRole.Doc_Control)
        ) {
          return `${contractId}/${actionId}/bluebeam/${subItem.Guid}`;
        } else 
        break;
      case SubmittalStatus.Compiling:
        if (coordinatorId === userId) {
          return `${contractId}/${actionId}/compile/${subItem.Guid}`;
        } else {
          return `${contractId}/${actionId}/response/${subItem.Guid}`;
        }
      case SubmittalStatus.Disposition:
      case SubmittalStatus.Compiling_Disposition:
        if (managerId === userId) {
          return `${contractId}/${actionId}/disposition/${subItem.Guid}`;
        } else {
          return `${contractId}/${actionId}/response/${subItem.Guid}`;
        }
      case SubmittalStatus.SPM_Review:
        if (srManagerId === userId) {
          return `${contractId}/${actionId}/disposition/${subItem.Guid}`;
        } else {
          return `${contractId}/${actionId}/view/${subItem.Guid}`;
        }
      case SubmittalStatus.DDC_Disposition:
        if(ddcManagerId === userId) {
          return `${contractId}/${actionId}/ddcDisposition/${subItem.Guid}`;
        } else
        {
          return `${contractId}/${actionId}/view/${subItem.Guid}`;
        }
      case SubmittalStatus.Reviewed:
      case SubmittalStatus.Reviewed_With_Comments:
      case SubmittalStatus.Accepted:
      case SubmittalStatus.Accepted_With_Comments:
      case SubmittalStatus.Revise_Resubmit:
        return subItem.BallInCourt === SubmittalRole.Doc_Control &&
          role === SubmittalRole.Doc_Control
          ? `${contractId}/${actionId}/review/${subItem.Guid}`
          : `${contractId}/${actionId}/view/${subItem.Guid}`;
      default:
        return `${contractId}/${actionId}/view/${subItem.Guid}`;
    }
  }
}

export function calculateReviewDate(
  baseDate: Date,
  page?: SubmittalPage,
): Date {
  const newDate = new Date(baseDate);
  const dayOfWeek = newDate.getDay();
  const dayOfMonth = newDate.getDate();

  if (dayOfWeek === 0) {
    newDate.setDate(dayOfMonth + (page?.ReviewDateNumber ?? 0) + 1);
  } else if (dayOfWeek === 6) {
    newDate.setDate(dayOfMonth + (page?.ReviewDateNumber ?? 0) + 2);
  } else {
    newDate.setDate(dayOfMonth + (page?.ReviewDateNumber ?? 0));
  }

  return newDate;
}

export function getDelegateUserIdByRoleId(
  roleId: string,
  delegate?: (ISubDelegateDetail | SubmittalDelegateDetail)[],
): string | null {
  if (delegate && delegate.length > 0) {
    return (
      delegate
        .sort(
          (a, b) =>
            (b.DateUpdated ? new Date(b.DateUpdated).getTime() : 0) -
            (a.DateUpdated ? new Date(a.DateUpdated).getTime() : 0),
        )
        .find((d) => {
          return d.RoleId === roleId;
        })?.UserId ?? null
    );
  }

  return null;
}

export function generateUserRowItem<T>(
  item: T & { UserInfo?: IUser },
  extraProperties?: any
): RowItem {
  let organization = "";

  if (item.UserInfo?.department) {
    const nameArray = item.UserInfo.department.split(" ");

    if (nameArray) {
      [organization] = nameArray;
    }
  }
  return {
    id: item.UserInfo.id,
    name: item.UserInfo.displayName ?? "",
    email: item.UserInfo.mail ?? "",
    organization,
    track: item.UserInfo.department ?? "",
    checked: false,
    ...extraProperties,
  };
}

export function combineUserInfo<T>(
  item: T,
  user?: IUser,
): T & { UserInfo?: IUser } {
  return { ...item, UserInfo: user };
}

export function canRevise(status: string): boolean {
  return [
    SubmittalStatus.Revise_Resubmit,
    SubmittalStatus.Accepted_With_Comments, 
    SubmittalStatus.Reviewed_With_Comments 
  ].some((s)=>s ===status);
}

export function isReviseDraft(submittal: IPSubItem): boolean {
  if(submittal.Status === SubmittalStatus.Draft) {
    const flowArray = submittal.submittal_flow.sort(
      (a, b) =>
        new Date(b.DateReceived).getTime() -
        new Date(a.DateReceived).getTime(),
    );
    let i = 0;
    while (i < flowArray.length && flowArray[i].Status === SubmittalStatus.Draft)
    {
      i++;
    }
    return i < flowArray.length && flowArray[i].Status === SubmittalStatus.QAQC_Fail;
  }
  return false;
}

export function dispositionFormName(subNumber: string): string {
  return `${subNumber} Disposition Form.pdf`;
}

export function failureFormName(subNumber: string): string {
  return `${subNumber} Submittal QAQC Check Failure Form.pdf`;
}

export function getDispositionText(disposition: number, submittalTypeId: string): string {
  switch (disposition) {
    case 1:
      return "Revise & Re-Submit";
    case 2:
      return submittalTypeId ===
        reviewSubmittalTypeId
          ? "Reviewed"
          : "Approved";
    case 3:
      return submittalTypeId ===
        reviewSubmittalTypeId
          ? "Reviewed w/ Comments"
          : "Approved w/ Comments";
    case 4:
      return "Denied";
    default:
      return "";
  }
}

export function errorHandle(error: HttpErrorResponse, activeModal: ModalContainerService, router: Router): void {
  if (error.status === 403 || error.status === 409) {
    const modalInstance1 = activeModal.open(NotificationComponent);
    modalInstance1.instance.type = NotificationType.Information;
    modalInstance1.instance.theme = "light";
    modalInstance1.instance.title = "Your Changes Can't be Saved";
    modalInstance1.instance.body = "Someone already saved changes. Please refresh your screen to see updates.";
    modalInstance1.result.then((result1) => {
      if (result1 === 0) {
        location.reload();
      }
    });
  } else {
    console.log(error);
    const modalInstance = activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Error";
    modalInstance.instance.body = error.message;
  }
}

export function hasChangesFileModel(newFiles: FileModel[], oldFiles: FileModel[]): boolean {
  if(newFiles.length !== oldFiles.length)
  {
    return true;
  }
  interface filePorperties {
    name: string;
    size: string;
  }
  function mapFile(file: FileModel[]): filePorperties[] {
    return file.map((f)=>{
      if(f.File)
      {
        return { name: f.File.name, size: f.File.size.toString() };
      }
      else{
        return { name: f.Name ?? '', size: f.Size ?? "0" };
      }
    })
  }
  const newFilePorperties = mapFile(newFiles);
  const oldFilePorperties = mapFile(oldFiles);
  return JSON.stringify(newFilePorperties) !== JSON.stringify(oldFilePorperties);
}

export function isValidUUID(id: string): boolean {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id);
}

export function getDateWithOffSet(date: string): string {
  const tempDate = new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
  return tempDate.toLocaleDateString();
}

export function getFixTimeDate(a: string): string {
  if (!a) return null;

  return getDateWithOffSet(a) + " 04:00 PM EST";
}

export function getAccTimeDate(a: string): string {
  if (!a) return null;
  const options = { hour: "2-digit", minute: "2-digit", hour12: true } as Intl.DateTimeFormatOptions;
  return `${getDateWithOffSet(a)} ${new Date(a).toLocaleTimeString("en-US", options)} EST`;
}
  
export function getWorkflowStatusArray(sub: IPSubListItem): string[] {
  if(sub?.submittal_flow)
  {
    return sub.submittal_flow.sort(
      (a, b) =>
        new Date(b.DateReceived).getTime() -
        new Date(a.DateReceived).getTime(),
    ).map((f)=>{return f.Status});
  }
  return [];
}

export function shouldShowDocControl(sub: IPSubListItem): boolean {
  return shouldShowManager(sub);
}

export function shouldShowManager(sub: IPSubListItem): boolean {
  return getWorkflowStatusArray(sub).includes(SubmittalStatus.In_Review) || isFinalStep(sub.Status);
}

export function shouldShowAltManager(sub: IPSubListItem): boolean {
  const arr = getWorkflowStatusArray(sub);
  return arr.includes(SubmittalStatus.Bluebeam) || arr.includes(SubmittalStatus.Compiling) || arr.includes(SubmittalStatus.Compiling_Disposition) || isFinalStep(sub.Status);
}

export function shouldShowCoordinator(sub: IPSubListItem): boolean {
  //return getWorkflowStatusArray(sub).includes(SubmittalStatus.Compiling) || getWorkflowStatusArray(sub).includes(SubmittalStatus.Bluebeam_Compiling) || getWorkflowStatusArray(sub).includes(SubmittalStatus.Compiling_Disposition) || isFinalStep(sub);
  return shouldShowManager(sub);
}

export function shouldShowSrManager(sub: IPSubListItem): boolean {
  return getWorkflowStatusArray(sub).includes(SubmittalStatus.DDC_Disposition) || getWorkflowStatusArray(sub).includes(SubmittalStatus.SPM_Review) || isFinalStep(sub.Status);
}

export function shouldShowDDCManager(sub: IPSubListItem): boolean {
  return getWorkflowStatusArray(sub).includes(SubmittalStatus.DDC_Disposition) || isFinalStep(sub.Status);
}

export function shouldShowAltDDCManager(sub: IPSubListItem): boolean {
  return isFinalStep(sub.Status);
}

export function isFinalStep(status: string): boolean {
  switch (status) {
    case SubmittalStatus.Canceled:
    case SubmittalStatus.Voided:
    case SubmittalStatus.Denied:
    case SubmittalStatus.Resubmitted:
    case SubmittalStatus.In_Revision:
    case SubmittalStatus.Revise_Resubmit:
    case SubmittalStatus.Accepted:
    case SubmittalStatus.Accepted_With_Comments:
    case SubmittalStatus.Reviewed:
    case SubmittalStatus.Reviewed_With_Comments:
    case SubmittalStatus.DB_Acknowledgment:
      return true;
    default:
      return false;
  }
}

export function isFinalStepForBluebeam(status: string): boolean {
  switch (status) {
    case SubmittalStatus.Revise_Resubmit:
    case SubmittalStatus.Accepted:
    case SubmittalStatus.Accepted_With_Comments:
    case SubmittalStatus.Reviewed:
    case SubmittalStatus.Reviewed_With_Comments:
      return true;
    default:
      return false;
  }
}

export function prepareMutiLineText(text: string | null | undefined): string {
  return text ? text.replace(/(?:\r\n|\r|\n)/g, '<br />') : "";
}

export function getUserNameById(contractUsers: IContractUserWithUserInfo[], id: string | undefined): string | undefined {
    return contractUsers.find((cu)=>{return cu.UserId === id})?.UserInfo?.displayName;
}

export function getDateDiff(date1: string, date2: string): string {
  const dateTime1 = new Date(new Date(date1).toLocaleDateString()).getTime();
  const dateTime2 = new Date(new Date(date2).toLocaleDateString()).getTime();
  return ((dateTime2 - dateTime1)/(1000 * 3600 * 24)).toFixed(0);
}

export function getBusinessDatesCount(startDate: string, endDate: string) {
  let count = 0;
  const start = new Date(new Date(new Date(startDate).toLocaleDateString()).getTime());
  const end = new Date(new Date(new Date(endDate).toLocaleDateString()).getTime());
  while (start < end) {
      const dayOfWeek = start.getDay();
      if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
      start.setDate(start.getDate() + 1);
  }
  return count;
}

export function getStatusText(status: string, isDB = false, reopened = false): string {
  switch (status) {
    case SubmittalStatus.In_Review:
      return isDB ? (reopened ? SubmittalStatus.Reopen_in_Progress : SubmittalStatus.Submitted) : SubmittalStatus.Distribution;
    case SubmittalStatus.Compiling:
      return isDB ? (reopened ? SubmittalStatus.Reopen_in_Progress : SubmittalStatus.Submitted) : SubmittalStatus.Review_Period;
    case SubmittalStatus.Disposition:
    case SubmittalStatus.Compiling_Disposition:
        return isDB ? (reopened ? SubmittalStatus.Reopen_in_Progress : SubmittalStatus.Submitted) : SubmittalStatus.PM_Review;
    case SubmittalStatus.DDC_Disposition:
    case SubmittalStatus.SPM_Review:
    case SubmittalStatus.Acknowledgement:
      return isDB ? (reopened ? SubmittalStatus.Reopen_in_Progress : SubmittalStatus.Submitted) : status;
    case SubmittalStatus.Bluebeam:
    case SubmittalStatus.Bluebeam_Compiling:
    case SubmittalStatus.Bluebeam_Request:
    case SubmittalStatus.Bluebeam_Uploaded:
    case SubmittalStatus.Bluebeam_Disposition:
      return isDB ? (reopened ? SubmittalStatus.Reopen_in_Progress : SubmittalStatus.Submitted) : SubmittalStatus.Bluebeam;
    case SubmittalStatus.Reviewed_With_Comments:
      return "Reviewed w/ Comments";
    case SubmittalStatus.Accepted_With_Comments:
      return "Approved w/ Comments";
    case SubmittalStatus.Accepted:
      return "Approved";
    case SubmittalStatus.Revise_Resubmit:
      return "Revise & Re-submit";
    case SubmittalStatus.QAQC_Fail:
      return "QA/QC Fail";
    default:
      return status;
  }
}

export function showDenied(subItem: IPSubItem, localSubmittalDocumentTypeService: LocalSubmittalDocumentTypeService): boolean {
  const documentType = localSubmittalDocumentTypeService.getItems().find((item) => {
    return item.Guid == getLatestCreateItem(subItem)?.DocumentTypeId;
  })?.Title;
  return ["Relief Event Documentation","Change Order Documentation","Waiver Approval Requests","Deviation Approval Requests","Modification Requests","TIA","Substitution"].includes(documentType ?? 'unknown');
}

export function includeDenied(documentType: string | null): boolean {
  return ["Relief Event Documentation","Change Order Documentation","Waiver Approval Requests","Deviation Approval Requests","Modification Requests","TIA","Substitution"].includes(documentType ?? 'unknown');
}

export function isDB(isConfidential: boolean, dbId: string, userId: string, role: string): boolean {
  return (isConfidential
    ? dbId === userId && role === SubmittalRole.DBAdmin 
    : role === SubmittalRole.DBAdmin) ||
    (isLocal() && role === SubmittalRole.Doc_Control);
}

export function isLocal(): boolean {
  return environment.stage === "local";
}

export function getRoleForSubmittal(userId: string, sub: IPSubItem, users: IContractUserWithUserInfo[], before?: string): string | null {
  const delegateDetails = (before? sub.submittal_delegate_detail.filter((d)=>{return new Date(d.DateUpdated) <= new Date(before)}) : sub.submittal_delegate_detail).sort((a,b)=> new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime());
  const coordinatorD = delegateDetails.find((d)=>{return d.RoleId === SubmittalRole.Coordinator});
  const managerD = delegateDetails.find((d)=>{return d.RoleId === SubmittalRole.Manager});
  const srManagerD = delegateDetails.find((d)=>{return d.RoleId === SubmittalRole.SR_Manager});
  const ddcManagerD = delegateDetails.find((d)=>{return d.RoleId === SubmittalRole.DDC_Manager});
  const coordinator = coordinatorD?.UserId ?? sub.CoordinatorId;
  const manager = managerD?.UserId ?? sub.ManagerId;
  const srManager = srManagerD?.UserId ?? sub.SrManagerId;
  const ddcManager = ddcManagerD?.UserId ?? sub.DDCManagerId;
  if(userId === coordinator)
  {
    return SubmittalRole.Coordinator;
  } else if(userId === manager)
  {
    return SubmittalRole.Manager
  } else if(userId === srManager)
  {
    return SubmittalRole.SR_Manager
  } else if(userId === ddcManager)
  {
    return SubmittalRole.DDC_Manager
  } else
  {
    const user = users.find((u)=>{return u.UserId === userId});
    if(user)
    {
      const role = user.contract_user_application_role.find((cr)=>{return cr.ApplicationId === submittalAppId});
      if(role)
      {
        return role.ApplicationRoleId;
      }
    }
  }
  return null;
}

export function isReopened(item: IPSubItem): boolean {
  const index = item.submittal_flow.findIndex((f)=>{return f.Status===SubmittalStatus.Request_to_Reopen && f.DateReceived===item.DateUpdated});
  return index > -1;
}

export function getLatestDueDateItem(sub: IPSubItem): SubmittalDuedate | undefined {
  if(sub?.submittal_duedate && sub.submittal_duedate.length > 0)
  {
    return sub.submittal_duedate.sort(
      (a, b) =>
        new Date(b.SubmittedDate).getTime() -
        new Date(a.SubmittedDate).getTime(),
    )[0];
  }
  return undefined;
}

export function getSelectBICforCurrentStatus(sub: IPSubItem): SubmittalSelectBic | undefined {
  return sub.submittal_select_bic
    .sort((a,b)=>new Date(b.DateUpdated).getTime()-new Date(a.DateUpdated).getTime())
    .find((s)=>s.Status===sub.Status);
}

export function getSelectBICforAcknowledgement(sub: IPSubItem): SubmittalSelectBic | undefined {
  return sub.submittal_select_bic
    .sort((a,b)=>new Date(b.DateUpdated).getTime()-new Date(a.DateUpdated).getTime())
    .find((s)=>s.Status===SubmittalStatus.Acknowledgement);
}

export function getLastAcknowledgementItem(sub: IPSubItem): SubmittalAcknowledgement | undefined {
  if(sub?.submittal_acknowledgement && sub.submittal_acknowledgement.length > 0)
  {
    return sub.submittal_acknowledgement
      .sort((a,b)=>new Date(b.AcknowledgedDate).getTime()-new Date(a.AcknowledgedDate).getTime())[0];
  }
  return undefined;
}

export function setOffsetDate(date: Date, day: number): Date {
  return new Date(new Date(date).setDate(date.getDate()+day));
}

export function isPDF(name: string | undefined): boolean {
  if(name)
  {
    return name.toLocaleLowerCase().includes('.pdf');
  }
  return false
}

export function getLatestCreateItem(sub: IPSubItem): IPSubCreateItem | undefined {
  if(sub?.submittal_create && sub.submittal_create.length > 0)
  {
    return sub.submittal_create.sort(
      (a, b) =>
        new Date(b.DateUpdated).getTime() -
        new Date(a.DateUpdated).getTime(),
    )[0];
  }
  return undefined;
}